import { json } from "react-router-dom";
import { ERROR_STATUS_MESSAGE } from "src/assets/lang/errorMessages";
import { CATALOGUE_IDS } from "src/constants/services";
import { ANCILLARY_MOCK } from "src/mockData/servicesMock";
import { additionalServicesApi, ancillaryServicesApi } from "src/services/api";
import { BAGGAGE } from "src/utils/baggage-utils";
import { getAllFlights } from "src/utils/results-utils";
import { getSelectedLocale } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";

const processSeatsItem = (itemId, carrier, service, catalogue) => {
  const idx = catalogue.findIndex((item) => item.id === itemId);
  if (idx !== -1) {
    catalogue[idx] = {
      ...catalogue[idx],
      data: catalogue[idx].data
        ? { ...catalogue[idx].data, [carrier]: { ...service } }
        : { [carrier]: { ...service } },
      isSeatsService: true,
    };
  }
};

export function processRestoredSeatsServices(data, travelPackage, catalogue) {
  const defaultService = {
    sum_total: 0,
    unit_price: 0,
    net_price: {
      price: 0,
      currency: "EUR",
    },
    markup: {
      price: 0,
      currency: "EUR",
    },
    total_price: {
      price: 0,
      currency: "EUR",
    },
    policy: {
      per_person: true,
      per_segment: true,
    },
  };
  const allSegments = getAllFlights(travelPackage);
  const airlinesList = [...new Set(allSegments.map((fl) => fl.carrier.code))];
  for (let i = 0; i < airlinesList.length; i++) {
    Object.keys(CATALOGUE_IDS.seats).forEach((seatType) => {
      let service = data.find((s) => s.airline === airlinesList[i] && s.id === seatType);
      processSeatsItem(
        CATALOGUE_IDS.seats[seatType],
        airlinesList[i],
        service ? service.data : defaultService,
        catalogue
      );
    });
    processSeatsItem(CATALOGUE_IDS.seats.random, airlinesList[i], defaultService, catalogue);
  }
}

export async function processSeatsServices(travelPackage, catalogue) {
  let result = {};
  const allSegments = getAllFlights(travelPackage);
  const airlinesList = [...new Set(allSegments.map((fl) => fl.carrier.code))];
  const promiseQueue = [];
  for (let i = 0; i < airlinesList.length; i++) {
    const promise = getAncillariesSeats(airlinesList[i]).then((data) => {
      Object.keys(data).forEach((seatType) =>
        processSeatsItem(
          CATALOGUE_IDS.seats[seatType],
          airlinesList[i],
          data[seatType],
          catalogue
        )
      );
      result[airlinesList[i]] = data;
    });
    promiseQueue.push(promise);
    processSeatsItem(
      CATALOGUE_IDS.seats.random,
      airlinesList[i],
      {
        sum_total: 0,
        unit_price: 0,
        net_price: {
          price: 0,
          currency: "EUR",
        },
        markup: {
          price: 0,
          currency: "EUR",
        },
        total_price: {
          price: 0,
          currency: "EUR",
        },
        policy: {
          per_person: true,
          per_segment: true,
        },
      },
      catalogue
    );
  }
  await Promise.all(promiseQueue);
  return result;
}

export function processRestoredAdditionalServices(serviceData, catalogue) {
  for (let i = 0; i < serviceData.length; i++) {
    const serviceName = serviceData[i].name;
    const insuranceOpts = Object.keys(CATALOGUE_IDS.TRAVEL_INSURANCE);
    const supportOpts = Object.keys(CATALOGUE_IDS.SUPPORT);
    if (insuranceOpts.includes(serviceName) || supportOpts.includes(serviceName)) {
      let nameKeyOpts, parentName;
      if (insuranceOpts.includes(serviceName)) {
        nameKeyOpts = insuranceOpts;
        parentName = "TRAVEL_INSURANCE";
      } else {
        nameKeyOpts = supportOpts;
        parentName = "SUPPORT";
      }
      nameKeyOpts.forEach((opt) => {
        const idx = catalogue.findIndex((item) => item.id === CATALOGUE_IDS[parentName][opt]);
        if (idx !== -1) {
          catalogue[idx] = {
            ...catalogue[idx],
            data: { ...serviceData[i].data },
            isAdditionalService: true,
          };
        }
      });
    } else {
      const idx = catalogue.findIndex((item) => item.id === CATALOGUE_IDS[serviceName]);
      if (idx !== -1) {
        catalogue[idx] = {
          ...catalogue[idx],
          data: { ...serviceData[i].data },
          isAdditionalService: true,
        };
      }
    }
  }
}

export async function processAdditionalServices(catalogue) {
  const serviceDataRes = await getServicesCatalogue();
  const serviceData = Object.values(serviceDataRes);

  for (let i = 0; i < serviceData.length; i++) {
    const service = serviceData[i];
    const parentName = service.name;
    if (["SUPPORT", "TRAVEL_INSURANCE"].includes(parentName)) {
      const nameKeyOpts = Object.keys(service.options);
      nameKeyOpts.forEach((opt) => {
        const idx = catalogue.findIndex(
          (item) =>
            item.id ===
            CATALOGUE_IDS[parentName][
              parentName === "TRAVEL_INSURANCE" ? opt.toUpperCase() : opt
            ] //TODO: ask backend to fix this
          // insurance service comes in varying case, unfortunately
        );
        if (idx !== -1) {
          catalogue[idx] = {
            ...catalogue[idx],
            data: { ...service.options[opt] },
            isAdditionalService: true,
          };
        }
      });
    } else {
      const idx = catalogue.findIndex((item) => item.id === CATALOGUE_IDS[parentName]);
      if (idx !== -1) {
        catalogue[idx] = {
          ...catalogue[idx],
          data: { ...service },
          isAdditionalService: true,
        };
      }
    }
  }
  return serviceData;
}

export async function getAncillariesSeats(carrierCode) {
  // return new Promise((resolve) => resolve(ANCILLARY_MOCK.seats));
  return ancillaryServicesApi
    .getServices(carrierCode)
    .then((res) => {
      console.log(res.data);
      const seats = res.data.seats;
      return seats;
    })
    .catch((err) => {
      customLog("Ancillary service fetching error occured: " + err);
      let lang = getSelectedLocale()?.split("_")[0] || "en";
      throw json({
        data: err,
        statusText: ERROR_STATUS_MESSAGE[lang].service,
      });
    });
}

export async function getServicesCatalogue() {
  // return new Promise((resolve) => resolve(ADDIT_MOCK));
  return additionalServicesApi
    .getServices()
    .then((res) => {
      console.log(res.data);
      const response = res.data;
      return response;
    })
    .catch((err) => {
      customLog("Additional service fetching error occured: " + err);
      let lang = getSelectedLocale()?.split("_")[0] || "en";
      throw json({
        data: err,
        statusText: ERROR_STATUS_MESSAGE[lang].service,
      });
    });
}

export function processBaggageServices(travelPackage, catalogue) {
  const bagTypes = [
    [BAGGAGE.personal, CATALOGUE_IDS.pi_baggage],
    [BAGGAGE.cabinBundle, CATALOGUE_IDS.cob_baggage],
  ];
  bagTypes.forEach((bt) => {
    const bagTypeData = travelPackage.baggage[bt[0]][0];
    const idx = catalogue.findIndex((item) => item.id === bt[1]);
    if (idx !== -1 && bagTypeData) {
      catalogue[idx] = {
        ...catalogue[idx],
        data: { ...bagTypeData },
        isBaggageService: true,
      };
    } else {
      catalogue.splice(idx, 1);
    }
  });

  const upgradeCabinBagData = travelPackage.baggage[BAGGAGE.cabinBundle][0]?.upgrade?.[0];
  const idx = catalogue.findIndex((item) => item.id === CATALOGUE_IDS.cob_baggage_upgrade);
  if (idx !== -1 && upgradeCabinBagData) {
    catalogue[idx] = {
      ...catalogue[idx],
      data: { ...upgradeCabinBagData },
      isBaggageService: true,
    };
  } else {
    catalogue.splice(idx, 1);
  }

  const rBagCatIDs = [
    CATALOGUE_IDS.r_baggage_def,
    CATALOGUE_IDS.r_baggage_larger,
    CATALOGUE_IDS.r_baggage_double,
  ];

  const singleBags = travelPackage.baggage[BAGGAGE.checked].filter((bag) => bag.piece === 1);
  singleBags.sort(
    (a, b) =>
      a.weight - b.weight ||
      a.r_baggage_price.total_price_per_person.price -
        b.r_baggage_price.total_price_per_person.price
  );

  if (travelPackage.baggage[BAGGAGE.checked].length === 0) {
    rBagCatIDs.forEach((rid) => {
      const idx = catalogue.findIndex((item) => item.id === rid);
      if (idx !== -1) {
        catalogue.splice(idx, 1);
      }
    });
  } else {
    rBagCatIDs.slice(0, 2).forEach((rid, i) => {
      const rBagData = singleBags[i];
      const idx = catalogue.findIndex((item) => item.id === rid);
      if (idx !== -1 && rBagData) {
        catalogue[idx] = {
          ...catalogue[idx],
          data: { ...rBagData },
          isBaggageService: true,
        };
      } else {
        catalogue.splice(idx, 1);
      }
    });

    const doubleBagData = travelPackage.baggage[BAGGAGE.checked].find(
      (bag) => bag.piece === 2
    );
    const idx = catalogue.findIndex((item) => item.id === rBagCatIDs[2]);
    if (idx !== -1 && doubleBagData) {
      catalogue[idx] = {
        ...catalogue[idx],
        data: { ...doubleBagData },
        isBaggageService: true,
      };
    } else {
      catalogue.splice(idx, 1);
    }
  }
}
