import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import classes from "../BaggageSelection.module.css";
import { InfantFareBenefits } from "./InfantFareBenefits";
import { BaggageOptionUnavailable } from "./BaggageOptionUnavailable";


export function BaggageSelectionNotAvailable() {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();

  return (
    <div className={`${classes.infant} ${isMobile ? classes.mobile : classes.desktop}`}>
      <InfantFareBenefits />
      <h2>{stringRes["booking.form.baggage.cabin.subtitle"]}</h2>
      <BaggageOptionUnavailable text={stringRes["booking.form.baggage.cabin.unavailable"]} />
      <h2>{stringRes["booking.form.baggage.cabin.subtitle"]}</h2>
      <BaggageOptionUnavailable text={stringRes["booking.form.baggage.checked.unavailable"]} />
    </div>
  );
}
