import { useRef } from "react";
import { useSelector } from "react-redux";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { selectCatalogue, selectServicePrice } from "src/store/catalogue";
import { getDisplayPriceValue } from "src/utils/utils";
import { useLocaleContext } from "src/context/locale-context";
import classes from "../BaggageSelection.module.css";
import { BAGGAGE_TITLE_MAP, BAG_IMG_SIZE } from "../utils";
import { PopularTag } from "./PopularTag";

export function CabinBaggageOption({
  passengerId,
  serviceId,
  selected,
  isPopular,
  upgradeWeight,
  onSelect,
  dimensions,
  description,
  images,
}) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const isSelectedItem = selected === serviceId;
  const baggagePrice = useSelector((state) =>
    selectServicePrice(selectCatalogue(state), serviceId)
  );

  const ref = useRef();

  const onSelectHandler = (isChecked) => {
    if (isChecked && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
    onSelect(isChecked, serviceId);
  };

  return (
    <RadioButton
      checked={isSelectedItem}
      id={"baggage-" + serviceId + passengerId}
      name={"baggage-selection-cabin" + passengerId}
      className={`${classes.baggageType} ${isSelectedItem ? classes.active : ""} ${
        baggagePrice === 0 ? classes.freeCabin : ""
      }`}
      onChange={onSelectHandler}>
      <h4 className={`${classes.bagPrice} ${baggagePrice === 0 ? classes.freeCabin : ""}`}>
        {baggagePrice > 0
          ? `${getDisplayPriceValue(baggagePrice)} ${currencySymbol}`
          : stringRes["booking.form.baggage.free"]}
      </h4>
      <div ref={ref} className={classes.optionContent}>
        {isPopular && <PopularTag text={stringRes["booking.form.baggage.popular"]} />}
        <h3 className={classes.optionTitle}>{stringRes[BAGGAGE_TITLE_MAP[serviceId]]}</h3>
        <p className={classes.optionDescription}>{description}</p>
        <div className={classes.bagIcons}>
          {images.map((imgSrc, i) => (
            <span className={classes.iconArea} key={serviceId + i}>
              <img style={{ width: `${BAG_IMG_SIZE[serviceId][i]}` }} alt="" src={imgSrc} />
              {upgradeWeight && i === 1 && (
                <span className={classes.badgeWeight}>
                  <span className={classes.upgrade}>{`+${upgradeWeight}`}</span>
                </span>
              )}
              <p className={classes.dimensionsInfo}>{dimensions[i]}</p>
            </span>
          ))}
        </div>
      </div>
    </RadioButton>
  );
}
