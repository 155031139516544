import classes from "./ResultsNotFound.module.css";
import { Fragment } from "react";
import Button from "src/components/UI/Button/Button";
import ActiveFilters from "../ActiveFilters/ActiveFilters";
import { useDispatch } from "react-redux";
import { filterSortActions } from "src/store/filter-sort";
import { useDeviceContext } from "src/context/device-context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocaleContext } from "src/context/locale-context";
import illustration1 from "src/assets/img/illustrations/illustr1.png";
import illustration2 from "src/assets/img/illustrations/illustr4.png";

function NotFoundGraphic({ image, description }) {
  return (
    <div className={classes.graphics}>
      <img src={image} alt={description} />
    </div>
  );
}

function NotFoundElement({ image, imageDesc, title, text }) {
  return (
    <Fragment>
      <NotFoundGraphic image={image} description={imageDesc} />
      <h1 className={classes.heading}>{title}</h1>
      <p className={classes.description}>{text}</p>
    </Fragment>
  );
}

export function SearchResultsNotFound() {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onClickHandler = () => {
    const q = searchParams.toString();
    navigate("/search/results?" + q, { replace: true });
  };

  return (
    <div
      className={`${classes.notFoundSearch} ${
        isMobile ? classes.mobile : classes.desktop
      }`}>
      <NotFoundElement
        image={illustration2}
        imageDesc="luggage on an empty terrain"
        title={stringRes["res.notfound.search.message.heading"]}
        text={stringRes["res.notfound.search.message.text"]}
      />
      <Button onClick={onClickHandler} name={stringRes["res.notfound.search.button"]} />
    </div>
  );
}

export function NetworkErrorResultsNotFound() {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onClickHandler = () => {
    const q = searchParams.toString();
    navigate("/search/results?" + q, { replace: true });
  };

  return (
    <div
      className={`${classes.notFoundNetwork} ${
        isMobile ? classes.mobile : classes.desktop
      }`}>
      <NotFoundElement
        image={illustration1}
        imageDesc="a person in the desert, shrugging their shoulders"
        title={stringRes["res.notfound.network.message.heading"]}
        text={stringRes["res.notfound.network.message.text"]}
      />
      <Button onClick={onClickHandler} name={stringRes["res.notfound.network.button"]} />
    </div>
  );
}

export function FilteredResultsNotFound() {
  const dispatch = useDispatch();
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  const onClearAllFilters = () => {
    dispatch(filterSortActions.clearAllFilters());
  };

  return (
    <div
      className={`${classes.notFoundFilters} ${
        isMobile ? classes.mobile : classes.desktop
      }`}>
      <NotFoundElement
        image={illustration1}
        imageDesc="a person in the desert, shrugging their shoulders"
        title={stringRes["res.notfound.filters.message.heading"]}
        text={stringRes["res.notfound.filters.message.text"]}
      />
      <div className={classes.filters}>
        <ActiveFilters isFilterPanel={false} />
      </div>
      <button onClick={onClearAllFilters} className={classes.delete} type="button">
        {stringRes["res.notfound.filters.button"]}
      </button>
    </div>
  );
}
