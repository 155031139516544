import { XIconUnavailable } from "src/components/UI/Icon/XIcon";
import classes from "../BaggageSelection.module.css";

export function BaggageOptionUnavailable({ text }) {
  return (
    <div className={classes.unavailable}>
      <XIconUnavailable />
      <span className={classes.description}>{text}</span>
    </div>
  );
}
