import classes from "./StopFilter.module.css";
import { STOPS } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { filterSortActions, selectFilters } from "src/store/filter-sort";
import Button from "src/components/UI/Button/Button";
import { Fragment, useContext, useEffect, useState } from "react";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { ModalElementContext } from "src/context/modal-element-context";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";

export function StopFilterItem({ id, label, checked, onSelect }) {
  const onInputChangeHandler = (isChecked) => {
    if (isChecked) {
      onSelect();
    }
  };

  return (
    <li>
      <RadioButton
        id={id}
        name="stop-filter"
        onChange={onInputChangeHandler}
        checked={checked}
      >
        <span className={classes.radioText}>{label}</span>
      </RadioButton>
    </li>
  );
}

function StopFilterContent({ onSelect, currentValue }) {
  const { stringRes } = useLocaleContext();

  const getStopValueLabel = (value) => {
    switch (value) {
      case STOPS.any:
        return stringRes["res.filter.stops.any"];
      case STOPS.direct:
        return stringRes["res.filter.stops.direct"];
      case STOPS.one:
        return stringRes["res.filter.stops.one"];
      case STOPS.two:
        return stringRes["res.filter.stops.two"];
    }
  };

  return (
    <form>
      <h1>{stringRes["res.filter.title.stops"]}</h1>
      <ul>
        {Object.values(STOPS).map((val) => (
          <StopFilterItem
            key={val}
            id={val}
            label={getStopValueLabel(val)}
            checked={val === currentValue}
            onSelect={() => onSelect(val)}
          />
        ))}
      </ul>
    </form>
  );
}

export function StopFilter() {
  const dispatch = useDispatch();
  const { stringRes } = useLocaleContext();
  const { onClose } = useContext(ModalElementContext);
  const { isMobile } = useDeviceContext();
  const stopsSettings = useSelector((state) => selectFilters(state, "stops"));

  const [state, setState] = useState(stopsSettings.value);

  const dispatchState = (value) => {
    dispatch(filterSortActions.applyFilter({ type: "stops", value: value }));
  };

  useEffect(() => {
    if (!stopsSettings.isOn) {
      setState(stopsSettings.value);
    }
  }, [stopsSettings.isOn]);

  const onSelectHandler = (value) => {
    setState(value);
    if (!isMobile) {
      dispatchState(value);
    }
  };

  const onConfirmValue = () => {
    dispatchState(state);
    onClose();
  };

  return (
    <Fragment>
      {isMobile && <CloseWindowButton onClick={onClose} />}
      <div
        className={`${classes.container} ${classes.stops} ${
          isMobile ? classes.mobile : classes.desktop
        }`}
      >
        <StopFilterContent onSelect={onSelectHandler} currentValue={state} />
        {isMobile && (
          <Fragment>
            <Button
              name={stringRes["res.filter.button.cancel"]}
              onClick={onClose}
              secondary
              cornerSm
              fontclass={classes.fontclass}
            />
            <Button
              name={stringRes["res.filter.button.done"]}
              onClick={onConfirmValue}
              primary
              cornerSm
              fontclass={classes.fontclass}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
