import { Interweave } from "interweave";
import { Fragment, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import { STATIC_ELEMENT } from "src/constants/static";
import { useLocaleContext } from "src/context/locale-context";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import useStaticContent from "src/hooks/useStaticContent";
import { bookingActions } from "src/store/booking";
import classes from "../PaymentOptions.module.css";

function transformTextToButtons(inputString, buttonsList) {
  let transformedString = inputString;
  buttonsList.forEach(
    (btn, index) =>
      (transformedString = transformedString.replace(
        btn.strVar,
        `<button value="${index}"><b>${btn.name}</b></button>`
      ))
  );
  return transformedString;
}

export const PaymentTermsComponent = forwardRef(function PaymentTermsComponent(props, ref) {
  const { stringRes } = useLocaleContext();
  const dispatch = useDispatch();
  const { openModal } = useModalContext();

  const { html: tos } = useStaticContent(STATIC_ELEMENT.terms);
  const { html: guarantee } = useStaticContent(STATIC_ELEMENT.guarantee);
  const { html: refunds } = useStaticContent(STATIC_ELEMENT.refunds);
  const { html: privacy } = useStaticContent(STATIC_ELEMENT.privacy);

  const isTermsConditionsAgreed = useSelector((state) => state.booking.isTermsAgreed);
  const isDealsOptedOut = useSelector((state) => state.booking.isOffersOptedOut);

  const buttonsList = [
    { name: "conditions", strVar: "##T_AND_C##", html: tos },
    { name: "guarantee", strVar: "##GUARANTEE_RULES##", html: guarantee },
    { name: "refunds", strVar: "##REFUND_RULES##", html: refunds },
    { name: "privacy", strVar: "##PRIVACY_POLICY##", html: privacy },
    {
      name: "Ryanair",
      strVar: "##RYANAIR_POLICY##",
      html: "",
      cb: () =>
        window.open(
          "https://www.ryanair.com/gb/en/useful-info/help-centre/terms-and-conditions",
          "_blank"
        ),
    },
  ].map(({ name, strVar, html, cb }) => ({
    name: stringRes[`booking.checkout.payment.terms.${name}`] || name,
    strVar,
    cb:
      cb ||
      (() =>
        openModal(MODAL_TYPE.documentView, {
          title: stringRes[`booking.checkout.payment.terms.${name}`],
          template: html,
        })),
  }));

  const termsAndConditions = transformTextToButtons(
    stringRes["booking.checkout.payment.terms"],
    buttonsList
  );

  return (
    <Fragment>
      <div
        ref={(r) => {
          if (ref) ref["TOSAgreement"] = r;
        }}
        className={`${classes.terms} ${
          !props.formState.tos.isValid && props.formState.tos.hasBeenTouched
            ? classes.warn
            : ""
        }`}>
        <Checkbox
          id="terms-and-conditions-agreement-check"
          isChecked={isTermsConditionsAgreed}
          onChange={() => {
            props.setFormState((fState) => {
              return {
                ...fState,
                tos: { isValid: !isTermsConditionsAgreed, hasBeenTouched: true },
              };
            });
            dispatch(bookingActions.updateTermsAgreementStatus(!isTermsConditionsAgreed));
          }}
          name="terms-and-conditions-agreement"
          isSameAsMobile
          checkMarkColor="#fff"
          isClickTextEnabled={false}
          label={
            <span className={classes.text}>
              <Interweave
                content={termsAndConditions}
                transform={(node, children) => {
                  if (node.tagName === "BUTTON") {
                    const idx = node.getAttribute("value");
                    return (
                      <button onClick={buttonsList[idx].cb} className={classes.docbtn}>
                        {children}
                      </button>
                    );
                  }
                }}
              />
            </span>
          }
        />
      </div>
      <div className={classes.dealsOptOut}>
        <Checkbox
          id="deals-vouchers-opt-out-option"
          isChecked={isDealsOptedOut}
          onChange={() =>
            dispatch(bookingActions.updateSkipOffersEmailsStatus(!isDealsOptedOut))
          }
          name="deals-vouchers-opt-out"
          isSameAsMobile
          isClickTextEnabled={false}
          checkMarkColor="#fff"
          label={
            <span className={classes.text}>
              {stringRes["booking.checkout.payment.deals.optout"]}{" "}
              <Link target="_blank" to="/info/terms-of-service">
                <b>{stringRes["booking.checkout.payment.deals.optout.info"]}</b>
              </Link>
            </span>
          }
        />
      </div>
    </Fragment>
  );
});
