import classes from "./LoginOptions.module.css";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { useDeviceContext } from "src/context/device-context";
import { Fragment, useState } from "react";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "../Modal/Modal";
import DesktopModal from "../Modal/DesktopModal";
import Button from "src/components/UI/Button/Button";
import { Link } from "react-router-dom";
import {
  AppleIconLogin,
  EmailIconLogin,
  FacebookIconLogin,
  GoogleIconLogin,
} from "src/components/UI/LoginIcon/LoginIcon";
import { RightArrowLogin } from "src/components/UI/Arrow/Arrow";
import { LOGIN_VENDOR } from "src/constants";
import { useLocaleContext } from "src/context/locale-context";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import { useAuth } from "src/context/auth-context";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { WarningIcon } from "src/components/UI/Icon/WarningIcon";
import { motion } from "framer-motion";
import illustration from "src/assets/img/illustrations/illustr3.png";

function LoginButtonContent({ vendor, selectedVendor, isLoading }) {
  const { stringRes } = useLocaleContext();
  let icon, name;
  if (vendor === LOGIN_VENDOR.email) {
    icon = <EmailIconLogin />;
    name = stringRes["login.modal.button.email"];
  } else if (vendor === LOGIN_VENDOR.facebook) {
    icon = <FacebookIconLogin />;
    name = stringRes["login.modal.button.facebook"];
  } else if (vendor === LOGIN_VENDOR.google) {
    icon = <GoogleIconLogin />;
    name = stringRes["login.modal.button.google"];
  }
  // else if (vendor === LOGIN_VENDOR.apple) {
  //   icon = <AppleIconLogin color="white" />;
  //   name = stringRes["login.modal.button.apple"];
  // }

  return (
    <span className={classes.loginVendor}>
      {icon}
      <span>{name}</span>

      {selectedVendor === vendor && isLoading ? (
        <span className={classes.loader}>
          <DotPulseLoader color="#c4cfd9" opacity={0.4} />
        </span>
      ) : (
        <span className={classes.arrow}>
          <RightArrowLogin color={vendor === LOGIN_VENDOR.apple ? "white" : undefined} />
        </span>
      )}
    </span>
  );
}

function LoginOptions({ modalId, onClose, state, onAfterLogin }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const { openModal } = useModalContext();
  const { login } = useAuth();

  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");

  const onSelectOption = (loginVendor) => {
    setSelectedVendor(loginVendor);

    const onSuccess = () => {
      setIsAuthorizing(false);
      onClose();
      if (onAfterLogin) onAfterLogin();
    };

    const onError = (errorCode) => {
      setIsAuthorizing(false);
      setSelectedVendor("");
      if (errorCode === "auth/account-exists-with-different-credential") {
        setErrorMessage(stringRes["login.modal.user.signin.exists"]);
      } else {
        setErrorMessage(stringRes["login.modal.error.credentials"]);
      }
    };

    if (loginVendor === LOGIN_VENDOR.email) {
      openModal(MODAL_TYPE[loginVendor], { onAfterLogin: onAfterLogin }, onClose);
    } else {
      setIsAuthorizing(true);
      login(loginVendor, null, null, onSuccess, onError);
    }
  };

  const innerContent = (
    <Fragment>
      <div className={classes.image}>
        <img src={illustration} alt="travellers walking, airport in the background" />
      </div>
      <h1>{stringRes["login.modal.title"]}</h1>
      <p className={classes.desc}>{`${stringRes["login.modal.description"]}`}</p>
      {errorMessage && (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          className={classes.error}>
          <WarningIcon size={16} />
          {errorMessage}
        </motion.div>
      )}
      <div className={classes.buttonList}>
        <Button
          secondary
          name={
            <LoginButtonContent
              selectedVendor={selectedVendor}
              isLoading={isAuthorizing}
              vendor={LOGIN_VENDOR.email}
            />
          }
          onClick={() => onSelectOption(LOGIN_VENDOR.email)}
        />
        {/* <Button
          secondary
          name={
            <LoginButtonContent
              selectedVendor={selectedVendor}
              isLoading={isAuthorizing}
              vendor={LOGIN_VENDOR.facebook}
            />
          }
          onClick={() => onSelectOption(LOGIN_VENDOR.facebook)}
        /> */}
        <Button
          secondary
          name={
            <LoginButtonContent
              selectedVendor={selectedVendor}
              isLoading={isAuthorizing}
              vendor={LOGIN_VENDOR.google}
            />
          }
          onClick={() => onSelectOption(LOGIN_VENDOR.google)}
        />
        {/* <Button
          fontclass={classes.apple}
          name={
            <LoginButtonContent
              selectedVendor={selectedVendor}
              isLoading={isAuthorizing}
              vendor={LOGIN_VENDOR.apple}
            />
          }
          onClick={() => onSelectOption(LOGIN_VENDOR.apple)}
        /> */}
      </div>
      <button onClick={() => {}} className={classes.incorrectEmail}>
        {stringRes["login.modal.button.incorrectEmail"]}
      </button>
      <p className={classes.terms}>
        <span>
          {`${stringRes["login.modal.terms.text"]} `}
          <Link target="_blank" to="/info/terms-of-service">
            <b>{stringRes["login.modal.terms.linkText"]}</b>
          </Link>
          {`. ${stringRes["login.modal.privacy.text"]} `}
          <Link target="_blank" to="/info/privacy-policy">
            <b>{stringRes["login.modal.privacy.linkText"]}</b>
          </Link>
          {"."}
        </span>
      </p>
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal modalId={modalId} isClosing={state.isClosing} onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>
          <button type="button" className={classes.close} onClick={onClose}>
            <XIconDesktop color={"#4F5E71"} />
          </button>
          {innerContent}
        </div>
      </DesktopModal>
    );
  }
}

export default LoginOptions;
