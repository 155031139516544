import { Link } from "react-router-dom";

export function transformDocument(node, children) {
  if (node.tagName === "PRE") {
    return (
      <div style={{ maxWidth: "100%" }}>
        <pre>{children}</pre>
      </div>
    );
  } else if (node.tagName === "A") {
    return (
      <Link target="_blank" to={node.getAttribute("href")}>
        {children}
      </Link>
    );
  }
}

export const getStyleSheet = (cssModuleName) => {
  const styleSheet = [...document.styleSheets].find((s) =>
    Array.from(s.cssRules)?.some((r) => r.selectorText?.startsWith(cssModuleName))
  );
  if (!styleSheet) {
    console.warn(`No style sheet found for selector: ${cssModuleName}`);
    return {};
  }
  console.log(styleSheet);
  return styleSheet;
};

export const getSelectorRules = (styleSheet, selector) => {
  const rules = [];
  [...styleSheet.cssRules].forEach((r) => {
    if (r.selectorText) {
      const selectorTextList = r.selectorText.split(", ");
      if (selectorTextList.includes(selector.name)) rules.push(r);
    }
  });
  return rules;
};

export const resolveCssVariable = (cssVarName) => {
  const root = document.documentElement;
  const rootStyles = getComputedStyle(root);
  const value = rootStyles.getPropertyValue(cssVarName).trim();
  return value;
};

export const getStylesFromRules = (cssRules) => {
  const styleObj = {};
  cssRules.forEach((rule) => {
    const ruleStyle = rule?.style;
    for (let i = 0; i < ruleStyle?.length; i++) {
      const prop = ruleStyle[i];
      let propValue = ruleStyle.getPropertyValue(prop);
      const matches = propValue.match(/^\s*var\((.+)\)\s*$/);
      if (matches) propValue = resolveCssVariable(matches[1]);
      if (propValue.includes("rem")) {
        const value = propValue.split("rem")[0];
        propValue = value * 16 + "px";
      }

      styleObj[prop] = propValue;
    }
  });

  return styleObj;
};
