import { createSelector, createSlice, nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { BAG_WEIGHT_CATEGORY, FARE_PAX_MAP, PASSENGER } from "src/constants";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { getRegisteredBaggageWeight } from "src/utils/baggage-utils";
import { getPackageFaresList } from "src/utils/results-utils";
import { selectAllPassengers, selectTravelPackage } from "./booking";
import { catalogueActions, selectCatalogue, selectServicePrice } from "./catalogue";
import { orderActions, selectAllBaggage, selectOrder } from "./order";
import {
  addPassenger,
  removePassenger,
  updatePassenger,
  updateTravelPackage,
} from "./actions";
import { doMoneyMultiplication, doPriceAddition } from "src/utils/utils";

const INIT_CART_STATE = {
  total: 0,
  items: [],
};

const cartOverviewSlice = createSlice({
  name: "cartOverview",
  initialState: INIT_CART_STATE,
  reducers: {
    setCartOverview: (state, action) => {
      const loadingKeys = state.items.filter((itm) => itm.loading).map((itm) => itm.key);
      state.items = action.payload.items.map((itm) => ({
        ...itm,
        loading: loadingKeys.includes(itm.key),
      }));
      state.total = action.payload.total;
    },
    clearCartOverview: (state) => {
      state.items = [];
      state.total = 0;
    },
    setCartItemLoading: (state, action) => {
      state.items = state.items.map((itm) => ({
        ...itm,
        loading: action.payload.keys.includes(itm.key),
      }));
    },
    resetCartLoading: (state) => {
      state.items = state.items.map((itm) => ({ ...itm, loading: false }));
    },
  },
});

export const { setCartOverview, clearCartOverview, setCartItemLoading, resetCartLoading } =
  cartOverviewSlice.actions;
export default cartOverviewSlice.reducer;

export const getCartOverview = (state) => state.cartOverview;

const selectBaggageOrderUnits = createSelector(selectAllBaggage, (baggage) => {
  const allCheckedBaggage = baggage.map((b) => b.checked.serviceId);
  const baggageItemUnits = {
    cabin: baggage
      .map((b) => b.cabin.serviceId)
      .filter((sid) => sid === CATALOGUE_IDS.cob_baggage).length,
    cabinUpgraded: baggage
      .map((b) => b.cabin.serviceId)
      .filter((sid) => sid === CATALOGUE_IDS.cob_baggage_upgrade).length,
    checkedSm: allCheckedBaggage.filter((sid) => sid === CATALOGUE_IDS.r_baggage_def).length,
    checkedLg: allCheckedBaggage.filter((sid) => sid === CATALOGUE_IDS.r_baggage_larger)
      .length,
    checkedDbl: allCheckedBaggage.filter((sid) => sid === CATALOGUE_IDS.r_baggage_double)
      .length,
    protection: baggage
      .map((b) => b.protection.serviceId)
      .filter((sid) => sid === CATALOGUE_IDS.BAG_PROTECTION).length,
  };
  return baggageItemUnits;
});

const selectOrderDetails = createSelector(
  [
    selectOrder,
    selectCatalogue,
    selectAllPassengers,
    selectBaggageOrderUnits,
    selectTravelPackage,
  ],
  (order, catalogue, passengers, baggageItemUnits, travelPackage) => {
    const itemBreakdownOrder = {
      items: [
        {
          key: "cabinBundle",
          serviceId: CATALOGUE_IDS.cob_baggage,
          units: baggageItemUnits.cabin,
          isActiveOrderItem: baggageItemUnits.cabin > 0,
        },
        {
          key: "cabinBundleUpgrade",
          serviceId: CATALOGUE_IDS.cob_baggage_upgrade,
          units: baggageItemUnits.cabinUpgraded,
          isActiveOrderItem: baggageItemUnits.cabinUpgraded > 0,
        },
        {
          key: "checkedBaggageSmall",
          serviceId: CATALOGUE_IDS.r_baggage_def,
          units: baggageItemUnits.checkedSm,
          isActiveOrderItem: baggageItemUnits.checkedSm > 0,
          isCheckedBag: true,
          weight: getRegisteredBaggageWeight(
            travelPackage,
            false,
            BAG_WEIGHT_CATEGORY.default
          ),
        },
        {
          key: "checkedBaggageLarge",
          serviceId: CATALOGUE_IDS.r_baggage_larger,
          units: baggageItemUnits.checkedLg,
          isActiveOrderItem: baggageItemUnits.checkedLg > 0,
          isCheckedBag: true,
          weight: getRegisteredBaggageWeight(travelPackage, false, BAG_WEIGHT_CATEGORY.larger),
        },
        {
          key: "checkedBaggageDouble",
          serviceId: CATALOGUE_IDS.r_baggage_double,
          units: baggageItemUnits.checkedDbl,
          isActiveOrderItem: baggageItemUnits.checkedDbl > 0,
          isCheckedBag: true,
          weight: getRegisteredBaggageWeight(travelPackage, true),
        },
        {
          key: "checkedBaggageProtection",
          serviceId: CATALOGUE_IDS.BAG_PROTECTION,
          units: baggageItemUnits.protection,
          isActiveOrderItem: baggageItemUnits.protection > 0,
        },
        { key: "connectionProtection", serviceId: order.connectionProtection.serviceId },
        { key: "changeProtection", serviceId: order.changeProtection.serviceId },
        { key: "travelInsurance", serviceId: order.travelInsurance.serviceId },
        { key: "supportService", serviceId: order.supportService.serviceId },
        { key: "airHelpService", serviceId: order.airHelpService.serviceId },
        { key: "bookingSMSService", serviceId: order.bookingSMSService.serviceId },
        { key: "onlineCheckin", serviceId: order.onlineCheckin.serviceId },
        { key: "priceLock", serviceId: order.priceLock.serviceId },
        { key: "flightsSMSService", serviceId: order.flightsSMSService.serviceId },
      ],
    };

    let adultNum = passengers.filter((p) => p.ageGroup === PASSENGER.adult).length;
    let childrenNum = passengers.filter((p) => p.ageGroup === PASSENGER.child).length;
    let infantNum = passengers.filter((p) => p.ageGroup === PASSENGER.infant).length;

    const faresList = getPackageFaresList(travelPackage);
    const faresGroups = {};

    faresList.forEach((f) => {
      if (!faresGroups[f.type]) {
        faresGroups[f.type] = new Map();
      }

      const hasPassengersLeftToProcess =
        (FARE_PAX_MAP[f.type] === PASSENGER.adult && adultNum > 0) ||
        (FARE_PAX_MAP[f.type] === PASSENGER.child && childrenNum > 0) ||
        (FARE_PAX_MAP[f.type] === PASSENGER.infant && infantNum > 0);

      if (hasPassengersLeftToProcess) {
        const unitWholes = Math.floor(f.amount);
        const unitCents = Math.round((f.amount % 1) * 100) / 100;
        let price = unitWholes + unitCents;

        if (faresGroups[f.type].has(price)) {
          faresGroups[f.type].set(price, faresGroups[f.type].get(price) + 1);
        } else {
          faresGroups[f.type].set(price, 1);
        }

        if (FARE_PAX_MAP[f.type] === PASSENGER.adult) adultNum--;
        if (FARE_PAX_MAP[f.type] === PASSENGER.child) childrenNum--;
        if (FARE_PAX_MAP[f.type] === PASSENGER.infant) infantNum--;
      }
    });

    console.log(faresGroups);

    const paxFareItems = [];
    Object.entries(FARE_PAX_MAP).forEach((e) => {
      let key =
        e[1] === PASSENGER.infant
          ? "infantFare"
          : e[1] === PASSENGER.child
          ? "childFare"
          : "adultFare";
      if (faresGroups[e[0]]) {
        faresGroups[e[0]].forEach((qty, price) => {
          paxFareItems.push({
            key: key,
            units: qty,
            price: price,
            isActiveOrderItem: qty > 0,
          });
        });
      }
    });
    itemBreakdownOrder.items.unshift(...paxFareItems);

    //ADD SEATS BREAKDOWN
    order.seatType.forEach((seat) => {
      const fromDir = travelPackage.segments[seat.segment].dep.airport.code;
      const toDir = travelPackage.segments[seat.segment].arr.airport.code;
      const fromCity = travelPackage.segments[seat.segment].dep.city.title;
      const toCity = travelPackage.segments[seat.segment].arr.city.title;
      itemBreakdownOrder.items.push({
        key: seat.serviceId,
        serviceId: seat.serviceId,
        airline: seat.airline,
        route: `${fromDir} - ${toDir}`,
        routeFull: `${fromCity} - ${toCity}`,
        units: 1,
      });
    });

    const prices = itemBreakdownOrder.items.map((item) => {
      if (["adultFare", "childFare", "infantFare"].includes(item.key)) {
        return item.price;
      }
      let identifier;
      if (Object.values(CATALOGUE_IDS.seats).includes(item.key)) {
        identifier = item.airline;
      }
      return selectServicePrice(catalogue, item.serviceId, identifier);
    });

    itemBreakdownOrder.items.forEach((item, i) => {
      item.id = nanoid();
      item.price = prices[i];
      item.isActiveOrderItem =
        item.isActiveOrderItem !== undefined
          ? item.isActiveOrderItem
          : item.serviceId === SERVICE_TYPE.REFUSED || item.units === 0
          ? false
          : !!item.serviceId;
    });

    itemBreakdownOrder.total = itemBreakdownOrder.items
      .filter((v) => v.isActiveOrderItem === true)
      .map((v) => {
        return doMoneyMultiplication(v.price, v.units || 1);
      })
      .reduce((t, c) => doPriceAddition(t, c), 0);
    return itemBreakdownOrder;
  }
);

// Thunk action
const recalculateCart = () => (dispatch, getState) => {
  const state = getState();
  const cartOverview = selectOrderDetails(state);
  dispatch(setCartOverview(cartOverview));
};

const getActionTypes = (actions) => {
  return Object.keys(actions).map((actionKey) => actions[actionKey].type);
};

const orderActionTypes = getActionTypes(orderActions);

export const cartMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  // actions that trigger cart recalculation
  const actionsToRecalculate = [
    ...orderActionTypes,
    catalogueActions.calculateCatalogue.type,
    addPassenger.type,
    updatePassenger.type,
    removePassenger.type,
    updateTravelPackage.type,
  ];

  const currentUrl = window.location.href;
  const currentPage = currentUrl
    .split(window.location.origin)[1]
    ?.split("?")[0]
    .split("/")
    .filter((path) => path)?.[0];

  if (currentPage === "booking" && actionsToRecalculate.includes(action.type)) {
    console.log("cart middleware working");
    store.dispatch(recalculateCart());
  }

  return result;
};
