import { useLocaleContext } from "src/context/locale-context";
import classes from "./LostBaggageProtection.module.css";
import { useDeviceContext } from "src/context/device-context";
import { Link } from "react-router-dom";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { Fragment } from "react";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import { Checkmark, FatCheckmark } from "src/components/UI/Icon/Checkmark";
import bagProtectionIcon from "src/assets/img/blueRibbonLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "src/store/order";
import { selectCatalogue, selectServicePrice } from "src/store/catalogue";
import { benefitsKeys } from "../BaggageSelection/utils";
import { getDisplayPriceValue } from "src/utils/utils";

export function LostBaggageProtection({ passengerId, isProtectionOn }) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();

  const protectionPrice = useSelector((state) =>
    selectServicePrice(selectCatalogue(state), CATALOGUE_IDS.BAG_PROTECTION)
  );

  const onBaggageProtectionChange = (id, doProtect) => {
    const serviceId = doProtect ? CATALOGUE_IDS.BAG_PROTECTION : SERVICE_TYPE.REFUSED;
    dispatch(orderActions.changeCheckedBaggageProtection({ passengerId, serviceId }));
  };

  return (
    <div
      className={`${isMobile ? classes.mobile : classes.desktop} ${
        classes.baggageProtection
      }`}>
      <Checkbox
        id={"checked-baggage-protection" + passengerId}
        isChecked={isProtectionOn}
        label={
          <Fragment>
            <span className={classes.checkbox}>
              {isMobile
                ? stringRes["booking.mob.form.baggage.protection"]
                : stringRes["booking.form.baggage.protection"] + " "}
              {!isMobile && (
                <Link target="_blank" to="/info/terms-of-service">
                  <b>{stringRes["booking.form.baggage.protection.name"]}</b>
                </Link>
              )}
              <b className={classes.price}>{`+ ${getDisplayPriceValue(
                protectionPrice
              )} ${currencySymbol}`}</b>
              {isMobile && <img style={{ width: "2.5rem" }} src={bagProtectionIcon} alt="" />}
            </span>
          </Fragment>
        }
        onChange={onBaggageProtectionChange}
        name={"checked-baggage-protection-option" + passengerId}
        // className={classes.checkbox}
        isSameAsMobile
        checkMarkColor="#fff"
      />
      <ul className={classes.benefits}>
        {benefitsKeys.map((k) => (
          <li key={k} className={classes.feature}>
            <span key={k} className={classes.checkmark}>
              {isMobile ? <FatCheckmark /> : <Checkmark color="#04b1a7" />}
            </span>
            <span className={classes.description}> {stringRes[k]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
