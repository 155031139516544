export function Checkmark({ color = "var(--primary-1)" }) {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.97"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3867 0.472715C16.0206 0.405254 16.3852 0.685215 16.4804 1.31256C16.4548 1.5199 16.3832 1.70869 16.2656 1.87897C13.2903 5.16674 10.3151 8.45447 7.33982 11.7422C6.98009 11.9329 6.62853 11.9199 6.28513 11.7032C4.53564 10.0696 2.79736 8.42244 1.07029 6.76178C0.79982 6.19314 0.936538 5.75693 1.48044 5.45318C1.71947 5.37143 1.95384 5.38443 2.18357 5.49225C3.54939 6.68869 4.90353 7.89963 6.24607 9.12506C6.67255 9.474 7.08271 9.45447 7.47654 9.06647C10.0077 6.2358 12.5532 3.41678 15.1133 0.609434C15.208 0.562051 15.2992 0.516504 15.3867 0.472715Z"
        fill={color}
      />
    </svg>
  );
}

export function FatCheckmark({ color = "#12B169" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.11088 8.70545C3.86517 8.4479 3.45719 8.43829 3.19963 8.684C2.94207 8.92971 2.93247 9.33769 3.17818 9.59525L5.73665 12.2771C6.00009 12.5532 6.44446 12.5412 6.69264 12.2513L12.8451 5.06366C13.0766 4.79324 13.045 4.38637 12.7746 4.1549C12.5042 3.92342 12.0973 3.95499 11.8658 4.22542L6.46489 10.5351C6.31087 10.715 6.03509 10.7224 5.87159 10.5511L4.11088 8.70545Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
}
