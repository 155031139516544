import { InfoIcon } from "src/components/UI/Icon/InfoIcon";
import classes from "./FormInput.module.css";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import {
  getDOBObjectFromDateString,
  getFormattedDOBDate,
  isValidDOB,
} from "src/utils/date-utils";
import useTooltip from "src/hooks/useTooltip";
import { FormTooltip } from "src/components/UI/Tooltip/Tooltip";
import { WarningIcon } from "src/components/UI/Icon/WarningIcon";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { PASSENGER } from "src/constants";
import { AGE_GROUP_MIN } from "src/constants/validation";
import { AGE_GROUP_MAX } from "src/constants/validation";
import { EyeIcon } from "src/components/UI/Icon/EyeIcon";

export function FormInputPassword({
  value,
  onChange,
  isValid,
  isActive,
  onFocus,
  onBlur,
  name,
  onEnterKey,
  tabIndex,
}) {
  const { isMobile } = useDeviceContext();
  const [isInit, setIsInit] = useState(true);
  const [cursor, setCursor] = useState(null);
  const r = useRef();

  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (!isInit) {
      const input = r.current;
      if (input) {
        input.setSelectionRange(cursor, cursor);
      }
    } else {
      setIsInit(false);
    }
  }, [r, cursor, value, isInit]);

  const onKeyUpHandler = (e) => {
    if (e.key === "Enter" && onEnterKey) {
      onEnterKey();
    }
  };

  return (
    <div
      className={`${isMobile ? classes.mobile : classes.desktop} ${classes.field} ${
        isValid ? "" : classes.invalid
      } ${isActive ? classes.active : ""}`.trim()}>
      <input
        ref={r}
        tabIndex={tabIndex}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => {
          setCursor(e.target.selectionStart);
          onChange(e);
        }}
        type={showPass ? "text" : "password"}
        name={name}
        onKeyUp={onKeyUpHandler}
        required
      />
      {value && (
        <button
          className={classes.eye}
          onTouchStart={() => setShowPass(true)}
          onTouchEnd={() => setShowPass(false)}
          onMouseDown={() => setShowPass(true)}
          onMouseUp={() => setShowPass(false)}>
          <EyeIcon />
        </button>
      )}
    </div>
  );
}

export function FormInputText({
  placeholder,
  value,
  onChange,
  isValid,
  isActive,
  onFocus,
  onBlur,
  name,
  onEnterKey,
  autocomplete = "off",
}) {
  const { isMobile } = useDeviceContext();
  const [isInit, setIsInit] = useState(true);
  const [cursor, setCursor] = useState(null);
  const r = useRef();

  useEffect(() => {
    if (!isInit) {
      const input = r.current;
      if (input) {
        input.setSelectionRange(cursor, cursor);
      }
    } else {
      setIsInit(false);
    }
  }, [r, cursor, value, isInit]);

  const onKeyUpHandler = (e) => {
    if (e.key === "Enter" && onEnterKey) {
      onEnterKey();
    }
  };

  return (
    <div
      className={`${isMobile ? classes.mobile : classes.desktop} ${classes.field} ${
        isValid ? "" : classes.invalid
      } ${isActive ? classes.active : ""}`.trim()}>
      <input
        ref={r}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => {
          setCursor(e.target.selectionStart);
          onChange(e);
        }}
        placeholder={placeholder}
        type="text"
        name={name}
        autoComplete={autocomplete}
        onKeyUp={onKeyUpHandler}
      />
    </div>
  );
}

export function FormCountryInputDropdown({
  placeholder,
  value,
  optionGroups,
  onSelect,
  isValid,
  isActive,
  onFocus,
  onBlur,
}) {
  const { isMobile } = useDeviceContext();

  return (
    <div className={`${isMobile ? classes.mobile : classes.desktop} ${classes.dropdown}`}>
      <select
        required
        defaultValue={value}
        onChange={(e) => onSelect(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`${classes.field} ${isActive ? classes.active : ""} ${
          isValid ? "" : classes.invalid
        }`.trim()}>
        <option className={classes.placeholder} value="" disabled hidden>
          {placeholder}
        </option>

        {optionGroups.map((options, i) => (
          <Fragment key={"nat-group" + i}>
            {Object.keys(options).map((optKey) => (
              <option className={classes.option} key={optKey}>
                {options[optKey]}
              </option>
            ))}
            {i === 0 && (
              <option className={classes.separator} value="" disabled>
                {"".padEnd(25, "- ")}
              </option>
            )}
          </Fragment>
        ))}
      </select>
    </div>
  );
}

export function FormInputDropdown({
  placeholder,
  value,
  options,
  onSelect,
  isValid,
  isActive,
  onFocus,
  onBlur,
  name,
  id,
  disabled,
}) {
  const { isMobile } = useDeviceContext();

  return (
    <div className={`${isMobile ? classes.mobile : classes.desktop} ${classes.dropdown}`}>
      <select
        disabled={disabled}
        id={id}
        name={name}
        required
        defaultValue={value}
        onChange={(e) => onSelect(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
        autoFocus={false}
        className={`${classes.field} ${isActive ? classes.active : ""} ${
          isValid ? "" : classes.invalid
        }`.trim()}>
        <option className={classes.placeholder} value="" disabled hidden>
          {placeholder}
        </option>
        {Object.keys(options).map((optKey) => (
          <option className={classes.option} value={optKey} key={optKey}>
            {options[optKey]}
          </option>
        ))}
      </select>
    </div>
  );
}

const MONTH_KEYS = [
  "calendar.month.jan",
  "calendar.month.feb",
  "calendar.month.mar",
  "calendar.month.apr",
  "calendar.month.may",
  "calendar.month.jun",
  "calendar.month.jul",
  "calendar.month.aug",
  "calendar.month.sep",
  "calendar.month.oct",
  "calendar.month.nov",
  "calendar.month.dec",
];

const DIGIT_PATTERN = /^\d+$/;

export function FormInputDOB({
  value,
  ageGroup,
  onChange,
  isValid,
  isActive,
  onFocus,
  onBlur,
}) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const [state, setState] = useState({ day: "", month: "", year: "" });

  useEffect(() => {
    if (value && isValidDOB(value, AGE_GROUP_MAX[ageGroup], AGE_GROUP_MIN[ageGroup])) {
      const dateObj = getDOBObjectFromDateString(value);
      setState(dateObj);
    }
  }, []);

  const onSelectMonth = (month) => {
    setState((prev) => {
      return { ...prev, month: month };
    });
  };

  const onDayChange = (value) => {
    setState((prev) => {
      return { ...prev, day: DIGIT_PATTERN.test(value) || !value ? value : prev.day };
    });
  };

  const onYearChange = (value) => {
    setState((prev) => {
      return { ...prev, year: DIGIT_PATTERN.test(value) || !value ? value : prev.year };
    });
  };

  useEffect(() => {
    onChange(getFormattedDOBDate(state.day, state.month, state.year));
  }, [state]);

  return (
    <div
      className={`${isMobile ? classes.mobile : classes.desktop} ${classes.field} ${
        classes.dob
      } ${classes.dropdown} ${isValid ? "" : classes.invalid} ${
        isActive ? classes.active : ""
      }`.trim()}>
      <input
        value={state.day}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => onDayChange(e.target.value)}
        maxLength={2}
        placeholder={stringRes["booking.form.passenger.field.day"]}
        type="text"
      />
      <span className={classes.hrBorder}></span>

      <select
        required
        value={state.month}
        // defaultValue=""
        onChange={(e) => onSelectMonth(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        className={classes.month}>
        <option className={classes.placeholder} value="" disabled hidden>
          {stringRes["booking.form.passenger.field.month"]}
        </option>
        {MONTH_KEYS.map((k, i) => (
          <option key={k} value={i + 1}>
            {stringRes[k]}
          </option>
        ))}
      </select>
      <span className={classes.hrBorder}></span>

      <input
        value={state.year}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={4}
        onChange={(e) => onYearChange(e.target.value)}
        placeholder={stringRes["booking.form.passenger.field.year"]}
        type="text"
      />
    </div>
  );
}

export function FormInputLabel({ text, hasInfo, infoText, hasWarning, children }) {
  const { isMobile } = useDeviceContext();
  const [showCloseButton, setShowCloseButton] = useState(true);
  const [tooltipText, setTooltipText] = useState(infoText);
  const [tipTriggerRef, tooltipState, triggerTooltip] = useTooltip(
    { show: false, rightPos: 0, bottomPos: 0 },
    100
  );

  const tooltipCloseBtn = (
    <span onClick={() => triggerTooltip(false)} className={classes.close}>
      <XIconDesktop />
    </span>
  );

  const tooltipElement = (
    <div className={classes.tooltipInner}>
      <div className={classes.tooltipText}>
        <p>{tooltipText}</p>
      </div>
      {showCloseButton && tooltipCloseBtn}
    </div>
  );

  useEffect(() => {
    if (!hasWarning) {
      triggerTooltip(false);
      const timerId = setTimeout(() => {
        setShowCloseButton(true);
      }, 100);
      return () => clearTimeout(timerId);
    } else {
      const timerId = setTimeout(
        () => {
          setShowCloseButton(false);
          triggerTooltip(true);
        },
        triggerTooltip ? 100 : 0
      );
      triggerTooltip(false);
      return () => clearTimeout(timerId);
    }
  }, [hasWarning]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setTooltipText(infoText);
    }, 100);
    return () => clearTimeout(timerId);
  }, [infoText]);

  const mouseEvents = {
    onMouseOver: () => triggerTooltip(true),
  };

  return (
    <label className={isMobile ? classes.mobile : classes.desktop}>
      {tooltipState.show ? (
        <FormTooltip
          isOn={tooltipState.isOpening}
          isWarning={hasWarning}
          rightPos={0}
          bottomPos={tooltipState.triggerY - 12}
          width={isMobile ? "90%" : "100%"}>
          {tooltipElement}
        </FormTooltip>
      ) : (
        <span></span>
      )}
      <div className={classes.label}>
        {hasInfo || hasWarning ? (
          <Fragment>
            <span {...mouseEvents} className={classes.info} ref={tipTriggerRef}>
              {hasWarning ? <WarningIcon /> : <InfoIcon />}
            </span>
            <span {...mouseEvents} className={classes.labelText}>
              {text}
            </span>
          </Fragment>
        ) : (
          <span className={classes.labelText}>{text}</span>
        )}
      </div>
      {children}
    </label>
  );
}
