import { Fragment } from "react";
import classes from "./DetailedFlightsList.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { isDepartureArrivalDifferent } from "src/utils/results-utils";
import { getTotalDuration } from "../ResultsList/elements/FlightChainInfo/FlightChainInfo";
import { FlightCard } from "./FlightCard";
import { ConnectionElement } from "./ConnectionElement";
import { SymbolElement } from "./ConnectionElement";
import { nanoid } from "@reduxjs/toolkit";
import {
  FlightConnectionIcon,
  SelfTransferIcon,
  DifferentTransferAirportIcon,
  DifferentDepartureLocationIcon,
} from "src/components/UI/Icon/FlightDetailsSymbols";
import { useDeviceContext } from "src/context/device-context";
import { ClockIcon } from "src/components/UI/Icon/ClockIcon";
import { getFlightDateFormatted, getLocalizedFlightDuration } from "src/utils/date-utils";
import { useTenantContext } from "src/context/tenant-context";

export const CONNECTION_ELEMENTS = {
  connectionNote: {
    id: "connectionNote",
    strKey: "res.details.flight.warning.connection",
    varStr: "##CITY_AND_DURATION##",
    element: <FlightConnectionIcon />,
    rgb: [4, 177, 167],
  },
  selfTransfer: {
    id: "selfTransfer",
    strKey: "res.details.flight.warning.selfTransfer",
    varStr: "##AIRPORT_NAME##",
    element: <SelfTransferIcon />,
    rgb: [255, 82, 82],
  },
  diffTransferAirport: {
    id: "diffTransferAirport",
    strKey: "res.details.flight.warning.diff.transfer",
    element: <DifferentTransferAirportIcon />,
    rgb: [1, 114, 203],
  },
  diffDepartureLocation: {
    id: "diffDepartureLocation",
    strKey: "res.details.flight.warning.diff.departure",
    element: <DifferentDepartureLocationIcon />,
    rgb: [251, 180, 54],
  },
};

export function DetailedFlightsList({ flightChains, hasSelfTransfer }) {
  const { stringRes, currentLocale } = useLocaleContext();
  const { dateFormat } = useTenantContext();
  const { isMobile } = useDeviceContext();

  return (
    <div className={`${isMobile ? classes.mobile : classes.desktop} ${classes.flights}`}>
      {flightChains.map((chain, ci) => (
        <div key={nanoid(5)} className={classes.chain}>
          {!isMobile ? (
            <Fragment>
              <div className={classes.date}>
                {getFlightDateFormatted(chain[0].dep.date, dateFormat)}
              </div>
              <h3 className={classes.destination}>{`${chain[0].dep.city.title} - ${
                chain[chain.length - 1].arr.city.title
              }`}</h3>
            </Fragment>
          ) : (
            <h3 className={classes.destination}>{`${
              stringRes["res.details.flight.direction.to"]
            } ${chain[chain.length - 1].arr.city.title}`}</h3>
          )}

          <div className={classes.duration}>
            {isMobile && <ClockIcon />}
            {getTotalDuration(chain, currentLocale)}
          </div>

          {chain.map((flight, fi) => {
            const hasNext = !flight.last;
            let warnings = [];
            if (hasNext) {
              const nextFlight = chain[fi + 1];
              warnings.push({
                type: CONNECTION_ELEMENTS.connectionNote,
                varStrValue: `${nextFlight?.dep.city.title} ${getLocalizedFlightDuration(
                  flight.duration.transfer?.total_min,
                  currentLocale
                )}`,
              });
              if (hasSelfTransfer && nextFlight.baggage_recheck) {
                warnings.push({
                  type: CONNECTION_ELEMENTS.selfTransfer,
                  varStrValue: chain[fi + 1]?.dep.airport.title,
                });
              }
              if (flight.arr.airport.code !== nextFlight.dep.airport.code) {
                warnings.push({ type: CONNECTION_ELEMENTS.diffTransferAirport });
              }
            }
            return (
              <Fragment key={flight.dep.airport.title + flight.dep.timestamp}>
                <FlightCard flight={flight} isFirst={flight.first} hasNext={hasNext} />
                {hasNext && <ConnectionElement warnings={warnings} />}
              </Fragment>
            );
          })}

          {ci < flightChains.length - 1 &&
            isDepartureArrivalDifferent(chain, flightChains[ci + 1]) && (
              <div className={`${classes.diff} ${classes.connection}`}>
                <SymbolElement
                  i={0}
                  isLast
                  warning={{
                    type: CONNECTION_ELEMENTS.diffDepartureLocation,
                  }}
                />
                <div className={classes.transferNote}>
                  {stringRes[CONNECTION_ELEMENTS.diffDepartureLocation.strKey]}
                </div>
              </div>
            )}
        </div>
      ))}
    </div>
  );
}
