export const guestMenuItems = [
  {
    name: "main.nav.discover",
    items: [
      { name: "Subscribe to newsletter", to: "" },
      { name: "Stories", to: "" },
    ],
  },
  { name: "main.nav.terms", items: [{ name: "Link placeholder 1", to: "" }] },
  {
    name: "main.nav.company",
    items: [
      { name: "Link placeholder 2", to: "" },
      { name: "Link placeholder 4", to: "" },
    ],
  },
  {
    name: "main.nav.help",
    items: [
      { name: "Link placeholder 3", to: "" },
      { name: "Link placeholder 4", to: "" },
      { name: "Link placeholder 5", to: "" },
    ],
  },
];