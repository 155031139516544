import { Fragment, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import classes from "./FlightDetails.module.css";
import Card from "src/components/UI/Card/Card";
import useClickAway from "src/hooks/useClickAway";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "src/components/shared/modal/Modal/Modal";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { BookingNextButton } from "src/components/features/booking/StepContent/BookingNextButton";
import {
  getAllFlightChains,
  getPricePerPerson,
  getServiceFee,
  getTotalPricePerPerson,
} from "src/utils/results-utils";
import { splitStringByNewline } from "src/utils/string-utils";
import { nanoid } from "@reduxjs/toolkit";
import { FareItems } from "./FareItems";
import BackButton from "src/components/UI/BackButton/BackButton";
import { DetailedFlightsList } from "./DetailedFlightsList";
import Button from "src/components/UI/Button/Button";

const portalElement = document.getElementById("overlays");

function FlightDetailsModal({ isClosing, children, onBackdropClick }) {
  const modalRef = useRef();
  const animClass = isClosing ? classes.fadeout : classes.fadein;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.classList.add(classes.body);
    document.getElementsByTagName("html")[0].style.scrollbarWidth = "none";
    return () => {
      document.body.classList.remove(classes.body);
      document.body.style.overflow = "unset";
      document.getElementsByTagName("html")[0].style.scrollbarWidth = "unset";
    };
  }, []);

  const overlay = (
    <div className={`${classes.backdrop} ${animClass}`}>
      <Card className={classes.modal} ref={modalRef}>
        {children}
      </Card>
    </div>
  );

  useClickAway(modalRef, onBackdropClick);

  return <Fragment>{createPortal(overlay, portalElement)}</Fragment>;
}

function FlightDetailsMobileHeader({ onGoBack }) {
  const { stringRes } = useLocaleContext();

  return (
    <div className={classes.mobHeader}>
      <div className={classes.back}>
        <BackButton onClick={onGoBack} />
      </div>
      <div className={classes.summary}>{stringRes["res.details.flight.title.mob"]}</div>
    </div>
  );
}

function FlightDetailsContent({ item, onClose, onContinue, hideContinue }) {
  const { isMobile } = useDeviceContext();
  const { stringRes, currencySymbol } = useLocaleContext();
  const flightChains = getAllFlightChains(item);
  const hasSelfTransfer =
    item.is_vtrip &&
    item.data_provider === "MA" &&
    item.segments.some((fl) => fl.baggage_recheck);

  const priceBreakdownEl = (
    <div className={classes.priceBreakdown}>
      <span className={classes.price}>
        {isMobile ? stringRes["res.details.flight.price.ticket"] : null}
        {` ${getPricePerPerson(item)} ${currencySymbol}`}
      </span>
      <span>{`${stringRes["res.result.fee.service"]} ${getServiceFee(
        item
      )} ${currencySymbol}`}</span>
      <span>{`${stringRes["res.result.price.final"]} ${getTotalPricePerPerson(
        item
      )} ${currencySymbol}`}</span>
    </div>
  );

  return (
    <Fragment>
      {isMobile && <FlightDetailsMobileHeader onGoBack={onClose} />}
      <div className={classes.content}>
        {!isMobile && (
          <div className={classes.header}>
            <h2>{stringRes["res.details.flight.title"]}</h2>
            <button type="button" className={classes.close} onClick={onClose}>
              <XIconDesktop color={"#4F5E71"} />
            </button>
          </div>
        )}
        <DetailedFlightsList
          hasSelfTransfer={hasSelfTransfer}
          flightChains={flightChains}
        />
        <FareItems item={item} />
        {hasSelfTransfer && (
          <div className={classes.notes}>
            <h5>{stringRes["res.details.flight.selfTransfer.title"]}</h5>
            {splitStringByNewline(stringRes["res.details.flight.selfTransfer.desc"]).map(
              (str) => (
                <p className={classes.transfer} key={nanoid()}>
                  {str}
                </p>
              )
            )}
          </div>
        )}
        {isMobile && <div className={classes.notes}>{priceBreakdownEl}</div>}
        <div className={`${classes.footer} ${hideContinue ? classes.padded : ""}`}>
          {isMobile ? (
            <span className={classes.price}>{`${getPricePerPerson(
              item
            )} ${currencySymbol}`}</span>
          ) : (
            priceBreakdownEl
          )}
          {isMobile && !hideContinue ? (
            <Button
              onClick={onContinue}
              opts={{ width: "115px", height: "48px" }}
              name={stringRes["res.details.flight.button.continue"]}
            />
          ) : (
            !hideContinue && (
              <BookingNextButton
                onClick={onContinue}
                className={classes.withArr}
                buttonText={stringRes["res.details.flight.button.continue"]}
              />
            )
          )}
        </div>
      </div>
    </Fragment>
  );
}

function FlightDetails({ item, state, onClose, onContinue, hideContinue }) {
  const { isMobile } = useDeviceContext();

  const flightDetailsElement = (
    <FlightDetailsContent
      hideContinue={hideContinue}
      onContinue={onContinue}
      item={item}
      onClose={onClose}
    />
  );

  useEffect(() => {
    console.log("***********DETAILS*************");
    console.info(item);
    console.log("***********DETAILS -- END*************");
  }, [item]);

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal fullscreen isClosing={state.isClosing}>
          <div className={`${classes.mobile} ${classes.container}`}>
            {flightDetailsElement}
          </div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <FlightDetailsModal isClosing={state.isClosing} onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>
          {flightDetailsElement}
        </div>
      </FlightDetailsModal>
    );
  }
}

export default FlightDetails;
