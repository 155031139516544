import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { companyProfileApi } from "src/services/api";
import { customLog, hexToHSL } from "src/utils/utils";

const TenantContext = createContext({
  contactData: {},
  companyName: "",
  currency: "",
  email: "",
  phone: "",
  address: "",
  website: "",
  privacyNotes: "",
  dateFormat: "",
  shortDateFormat: "",
  phonePrefixes: [],
});

export const TenantContextManager = ({ tenantID, children }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    projectCurrency: "EUR",
    colorSchema: {
      primaryColor: "#1665e3",
      secondaryColor: "#001b38",
      accentColor: "#04b1a7",
    },
    contactInformation: {},
    dateFormat: "DD/MM/YYYY",
    shortDateFormat: "DD/MM",
    phonePrefixes: ["371", "370", "372"],
  });

  useEffect(() => {
    if (!tenantID) {
      throw Error("Missing tenant ID");
    }
    companyProfileApi
      .getCompanyProfileData(tenantID)
      .then((data) => {
        const tenantData = data.data;
        customLog(tenantData);
        setState((current) => {
          return { ...current, ...tenantData };
        });

        // Update the CSS variables
        const root = document.documentElement;
        root.style.setProperty("--primary-1", tenantData.colorSchema.primaryColor);
        root.style.setProperty("--primary-2", tenantData.colorSchema.secondaryColor);
        root.style.setProperty("--primary-3", tenantData.colorSchema.accentColor);

        const [h, s, l] = hexToHSL(tenantData.colorSchema.primaryColor);
        let newL = l <= 50 ? l + 4 : l - 4;
        root.style.setProperty("--primary-btn-hover-color", `hsl(${h}, ${s}%, ${newL}%)`);

        root.style.setProperty("--primary-tint", `hsl(${h}, 86%, 99%)`);
        root.style.setProperty("--primary-tint-darker", `hsl(${h - 10}, 86%, 97%)`);
        root.style.setProperty("--primary-tint-dark", `hsla(${h}, 82%, 49%, 0.1)`);
        root.style.setProperty("--tinted-border-color", `hsl(${h - 10}, 86%, 90%)`);
        root.style.setProperty("--tinted-btn-color", `hsla(${h - 15}, 95%, 43%, 0.08)`);
        root.style.setProperty("--tinted-btn-hover-color", `hsl(${h - 15}, 95%, 43%, 0.15)`);
        root.style.setProperty("--line-color", `hsl(${h + 5}, 92%, 56%, 0.4)`);
        root.style.setProperty(
          "--loader-gradient",
          `linear-gradient(${tenantData.colorSchema.primaryColor} 0 0), linear-gradient(${tenantData.colorSchema.primaryColor} 0 0), none`
        );
      })
      .catch(customLog);
  }, [dispatch, tenantID]);

  return (
    <TenantContext.Provider
      value={{
        contactData: state.contactInformation,
        currency: state.projectCurrency,
        email: state.contactInformation.email,
        phone: state.contactInformation.phone,
        address: state.contactInformation.address,
        website: state.contactInformation.website,
        companyName: state.contactInformation.companyName,
        privacyNotes: state.privacyNotes,
        dateFormat: state.dateFormat,
        shortDateFormat: state.shortDateFormat,
        phonePrefixes: state.phonePrefixes,
      }}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContext;

export const useTenantContext = () => useContext(TenantContext);
