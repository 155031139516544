import classes from "./SingleSearchForm.module.css";
import { useDispatch } from "react-redux";
import SearchFormText from "src/components/features/search/SearchForm/elements/SearchFormText/SearchFormText";
import SearchFormDate from "src/components/features/search/SearchForm/elements/SearchFormDate/SearchFormDate";
import { TRIP_TYPE, CABIN_CLASS_MAP } from "src/constants";
import { searchDataActions } from "src/store/search";
import {
  calculateTotalPassengers,
  customLog,
  getDisplayLocationValueMobile,
} from "src/utils/utils";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import { Fragment, useContext } from "react";
import Button from "src/components/UI/Button/Button";
import { SearchFormTextReadOnlyInputMobile } from "./SearchFormTextReadOnlyInputMobile";
import { ModalElementContext } from "src/context/modal-element-context";
import { useLocaleContext } from "src/context/locale-context";

export function MobileSingleSearchForm({
  onSearchFormClick,
  onCheckboxChange,
  checkValue,
  onStartSearch,
  isButtonEnabled,
  isResultsPage,
  searchData,
  dispatchSFState,
}) {
  const dispatch = useDispatch();
  const { stringRes, currentLocale } = useLocaleContext();
  const { onClose } = useContext(ModalElementContext);
  const lang = currentLocale.split("_")[0].toUpperCase();

  const isRoundTrip = searchData.tripType === TRIP_TYPE.roundtrip;
  const trip = searchData.trips[0];

  const srcLocationValue = getDisplayLocationValueMobile(trip.srcLocation, lang);
  const destLocationValue = getDisplayLocationValueMobile(trip.destLocation, lang);
  const passengerNum = calculateTotalPassengers(searchData.passengers);

  const cabinClass = searchData.cabinClass
    ? stringRes[CABIN_CLASS_MAP[searchData.cabinClass]].toLowerCase()
    : "any";

  const passengerValue =
    `${passengerNum} ` +
    (passengerNum > 1
      ? stringRes["sf.field.passenger.plural"]
      : stringRes["sf.field.passenger.single"]) +
    ", " +
    cabinClass;

  const onSwitchDirectionHandler = () => {
    if (isResultsPage) {
      const switchedLocations = {
        srcLocation: trip.destLocation,
        destLocation: trip.srcLocation,
      };
      dispatchSFState({ type: "UPDATE_TRIP", payload: switchedLocations, id: trip.id });
      dispatch(
        searchDataActions.updateUserInputTrip({
          tripId: trip.id,
          input: {
            srcLocation: switchedLocations.srcLocation.city,
            srcIata: switchedLocations.srcLocation.iata,
            destLocation: switchedLocations.destLocation.city,
            destIata: switchedLocations.destLocation.iata,
          },
        })
      );
    } else {
      customLog("rerender on onSwitchDirectionHandler SingleSearchForm DISPATCH");
      dispatch(searchDataActions.switchDestWithSrcLocation({ id: trip.id, lang }));
    }
  };

  const onClearEndDateHandler = () => {
    if (isResultsPage) {
      dispatchSFState({ type: "UPDATE_TRIP", payload: { endDate: "" }, id: trip.id });
    } else {
      const payload = { tripId: trip.id, date: "" };
      dispatch(searchDataActions.updateEndDate(payload));
    }
  };

  const inputProps = {
    tripId: trip.id,
    onSwitchDirection: onSwitchDirectionHandler,
    values: { src: srcLocationValue, dest: destLocationValue },
  };

  return (
    <Fragment>
      <form
        name="main-search-form"
        className={`${classes.search} ${isRoundTrip ? classes.roundtrip : classes.oneway}`}>
        <SearchFormTextReadOnlyInputMobile {...inputProps} onClick={onSearchFormClick} />
        <SearchFormDate
          id="start-date"
          className={classes.startDate}
          value={trip.startDate}
          label={stringRes["sf.field.date.depart"]}
          onClick={() => onSearchFormClick("start-date", trip.id)}
        />
        {isRoundTrip && (
          <SearchFormDate
            id="end-date"
            className={classes.endDate}
            value={trip.endDate}
            label={stringRes["sf.field.date.return"]}
            onClick={() => onSearchFormClick("end-date", trip.id)}
            onClear={onClearEndDateHandler}
          />
        )}
        <SearchFormText
          id="passengers"
          className={classes.passengers}
          value={passengerValue}
          label={stringRes["sf.field.passengers"]}
          onClick={() => onSearchFormClick("passengers")}
        />
        {isResultsPage && (
          <button
            className={classes.back}
            type="button"
            onClick={() => {
              dispatch(searchDataActions.fillUserInputPassengerOptions());
              dispatch(searchDataActions.fillUserInputTrips(lang));
              onClose();
            }}>
            {stringRes["sf.mobile.button.back"]}
          </button>
        )}
        <Button
          name={stringRes["sf.button.search"]}
          fontclass={classes.fontclass}
          onClick={onStartSearch}
          opts={{ minHeight: isResultsPage ? "3rem" : "3.5rem" }}
          {...(!isResultsPage || isButtonEnabled ? { primary: true } : { secondary: true })}
          wide={!isResultsPage}
          shadow={!isResultsPage}
          disabled={!isButtonEnabled}
        />
        {!isResultsPage && (
          <Checkbox
            id="include-accommodation"
            label={
              <span className={classes.checkbox2}>
                {stringRes["sf.opt.accommodation"]}
                <b>Booking.com</b>
              </span>
            }
            checkMarkColor="#fff"
            isChecked={checkValue}
            onChange={onCheckboxChange}
          />
        )}
      </form>
    </Fragment>
  );
}
