import React from "react";

export function InnerCheckmark({ color = "var(--primary-1)" }) {
  return (
    <svg
      style={{ pointerEvents: "none" }}
      width="16"
      height="16"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.97"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1094 3.57817C13.6165 3.5242 13.9082 3.74817 13.9844 4.25005C13.9638 4.41592 13.9066 4.56695 13.8125 4.70317C11.4323 7.33339 9.0521 9.96358 6.67188 12.5938C6.3841 12.7463 6.10285 12.7359 5.82813 12.5625C4.42854 11.2557 3.03791 9.93795 1.65626 8.60942C1.43988 8.15452 1.54926 7.80555 1.98438 7.56255C2.1756 7.49714 2.3631 7.50755 2.54688 7.5938C3.63954 8.55095 4.72285 9.5197 5.79688 10.5C6.13807 10.7792 6.46619 10.7636 6.78125 10.4532C8.80616 8.18864 10.8426 5.93342 12.8906 3.68755C12.9664 3.64964 13.0393 3.6132 13.1094 3.57817Z"
        fill={color}
      />
    </svg>
  );
}
