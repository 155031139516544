import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useRouteLoaderData } from "react-router-dom";
import { StepDirectionArrow } from "src/components/UI/Arrow/Arrow";
import Button from "src/components/UI/Button/Button";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { BillingInfoIcon, OrderPaymentIcon } from "src/components/UI/Icon/StepsIcons";
import { BOOKING_STEP } from "src/constants";
import { BOOKING } from "src/constants/booking";
import { BREAKDOWN_ITEM_STR_KEY } from "src/constants/order-str-map";
import { useBookingFlow } from "src/context/booking-flow-context";
import { useDeviceContext } from "src/context/device-context";
import { useGlobalLoading } from "src/context/loading-context";
import { useLocaleContext } from "src/context/locale-context";
import { paymentApi } from "src/services/api";
import store from "src/store";
import { resetAfterBooking } from "src/store/actions";
import {
  bookingActions,
  selectBillingData,
  selectContactData,
  selectPaymentData,
} from "src/store/booking";
import { getCartOverview } from "src/store/cartOverview";
import { getOrderPayload } from "src/utils/booking-utils";
import { clearBookingSessionData } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import BillingVATDetails from "../BillingVATDetails/BillingVATDetails";
import CheckoutSummary from "../CheckoutSummary/CheckoutSummary";
import { MobileBottomElement } from "../MobileBottomElement/MobileBottomElement";
import PaymentOptions, { PAYMENT_TYPE } from "../PaymentOptions/PaymentOptions";
import BookingSidePanel from "../SidePanel/BookingSidePanel";
import BookingStepTitle from "../StepTitle/BookingStepTitle";
import classes from "./StepContent.module.css";

const clearBookingData = () => {
  clearBookingSessionData();
  store.dispatch(resetAfterBooking());
};

export const validateStep = (
  storedBillingData,
  paymentData,
  isTOSAgreed,
  billingServiceRef,
  paymentServiceRef
) => {
  let warningElement = [];
  let isValidVATCheck, isPaymentMethodSelected;

  if (!storedBillingData.isVATRequired) {
    isValidVATCheck = true;
  } else {
    isValidVATCheck = Object.values(
      storedBillingData.isCompany ? storedBillingData.company : storedBillingData.person
    ).every((value) => !!value);
  }

  if (!isValidVATCheck) {
    warningElement.push(billingServiceRef.current);
  }

  if (
    (paymentData.type && paymentData.type !== PAYMENT_TYPE.paymentInitiation) ||
    (paymentData.type === PAYMENT_TYPE.paymentInitiation && paymentData.vendor)
  ) {
    isPaymentMethodSelected = true;
  } else {
    warningElement.push(paymentServiceRef.current.paymentMethod);
  }

  if (!isTOSAgreed) {
    warningElement.push(paymentServiceRef.current.TOSAgreement);
  }

  if (isValidVATCheck && isPaymentMethodSelected && isTOSAgreed) {
    return [true, null];
  } else {
    return [false, warningElement[0]];
  }
};

function CheckoutStepContent({ pageId }) {
  const { isMobile } = useDeviceContext();
  const { stringRes, currencySymbol, currentLocale } = useLocaleContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { goToPrevBookingPage, hasPrevious } = useBookingFlow();
  const { correlationID, travelPackage } = useRouteLoaderData("bookingRouteController");
  const { send } = useOutletContext();

  const billingServiceRef = useRef();
  const paymentServiceRef = useRef();
  const storedBillingData = useSelector(selectBillingData);
  const contactData = useSelector(selectContactData);
  const paymentData = useSelector(selectPaymentData);
  const isTOSAgreed = useSelector((state) => state.booking.isTermsAgreed);
  const cart = useSelector(getCartOverview);
  const cartTotal = cart.total;

  const { loading, setLoading } = useGlobalLoading();
  const isImmediatePayment = [
    PAYMENT_TYPE.paymentInitiation.key,
    PAYMENT_TYPE.cardPayments.key,
  ].includes(paymentData.type);

  const onGoToPrevStep = () => {
    goToPrevBookingPage(pageId);
  };

  const onInitiateNextStep = async () => {
    setLoading(true, 0.5);
    const [success, warningElement] = validateStep(
      storedBillingData,
      paymentData,
      isTOSAgreed,
      billingServiceRef,
      paymentServiceRef
    );
    if (success) {
      dispatch(bookingActions.resetFormState(BOOKING_STEP.checkoutPage.id));
      const cartWStrKeys = {
        total: cart.total,
        items: cart.items.map((item) => ({
          ...item,
          strKey: BREAKDOWN_ITEM_STR_KEY[item.key],
        })),
      };
      send({
        command: BOOKING.COMMIT_BOOKING,
        payload: {
          cart: cartWStrKeys,
          payment: {
            ...paymentData,
            amount: cartTotal,
            timestamp: Date.now(),
            status: "pending",
          },
        },
      });

      if (isImmediatePayment) {
        console.log("starting payment...");
        const lang = currentLocale.split("_")[0];
        const payload = getOrderPayload(
          correlationID,
          travelPackage.booking_number,
          lang,
          cartTotal,
          {
            ...paymentData,
            description:
              paymentData.type === PAYMENT_TYPE.cardPayments
                ? stringRes["payment.type.card.description.display"]
                : stringRes["payment.type.bank.description.display"],
          },
          storedBillingData,
          contactData.email
        );

        customLog(JSON.stringify(payload));
        paymentApi
          .processPaymentOrder(payload)
          .then((response) => {
            const paymentUrl = response.data;
            customLog(response.data);
            window.location.href = paymentUrl;
          })
          .catch((err) => {
            customLog(err);
            setLoading(false, null, 0.25);
            navigate(
              "/order/" +
                correlationID +
                "/error?booking-number=" +
                travelPackage.booking_number,
              {
                state: { source: "bookingCheckout" },
              }
            );
          });
      } else {
        setLoading(false, null, 0.25);
        navigate(
          "/order/" +
            correlationID +
            "/confirmation?type=" +
            paymentData.type +
            "&booking-number=" +
            travelPackage.booking_number,
          {
            state: { source: "bookingCheckout", paymentType: paymentData.type },
          }
        );
      }
    } else {
      setLoading(false, null, 0.25);
      dispatch(
        bookingActions.updateFormWarningStatus({
          step: BOOKING_STEP.checkoutPage.id,
          isIncomplete: true,
        })
      );
      dispatch(bookingActions.incrementValidationAttempts(BOOKING_STEP.checkoutPage.id));
      warningElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const payButtonText = isImmediatePayment
    ? `${stringRes["booking.checkout.payment.pay"]} ${cartTotal} ${currencySymbol}`
    : stringRes["booking.checkout.order.place"];

  if (!isMobile) {
    return (
      <section className={`${classes.container} ${classes.desktop} ${classes.checkout}`}>
        <BookingStepTitle step={pageId} />
        <div className={classes.mainGroup}>
          <div className={classes.main}>
            <CheckoutSummary />
          </div>
          <div className={classes.subheading}>
            <BillingInfoIcon />
            {stringRes["booking.checkout.billing.title"]}
          </div>
          <div className={classes.main}>
            <BillingVATDetails ref={billingServiceRef} />
          </div>
          <div className={classes.subheading}>
            <OrderPaymentIcon />
            {stringRes["booking.checkout.payment.title"]}
          </div>
          <div className={classes.main}>
            <PaymentOptions ref={paymentServiceRef} />
          </div>
        </div>

        <BookingSidePanel />
        <div className={classes.nextButton}>
          {hasPrevious(pageId) && (
            <Button
              secondary
              name={
                <span className={classes.withBackArr}>
                  <span>{stringRes["booking.back.button"]}</span>
                  <StepDirectionArrow back />
                </span>
              }
              onClick={onGoToPrevStep}
            />
          )}
          <Button
            disabled={!paymentData.type || !isTOSAgreed}
            opts={{ width: "fit-content", minWidth: "9.8125rem" }}
            name={loading ? <DotPulseLoader color="#fff" opacity={0.4} /> : payButtonText}
            onClick={onInitiateNextStep}
          />
        </div>
      </section>
    );
  } else {
    return (
      <section className={`${classes.container} ${classes.mobile} ${classes.checkout}`}>
        <div className={classes.row}>
          <BookingStepTitle step={pageId} />
          <CheckoutSummary />
        </div>
        <div className={classes.row}>
          <BillingVATDetails ref={billingServiceRef} />
        </div>
        <div className={classes.row}>
          <PaymentOptions ref={paymentServiceRef} />
        </div>
        <MobileBottomElement
          withPrice
          isDisabled={!paymentData.type || !isTOSAgreed}
          onGoToNextStep={onInitiateNextStep}
          buttonText={loading ? <DotPulseLoader color="#fff" opacity={0.4} /> : payButtonText}
        />
      </section>
    );
  }
}

export default CheckoutStepContent;
