import classes from "./ActiveFilters.module.css";
import { useDispatch, useSelector } from "react-redux";
import { filterSortActions, selectFilters } from "src/store/filter-sort";
import { ClearAllFiltersButton } from "./ClearAllFiltersButton";
import { Fragment } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { customLog } from "src/utils/utils";

function ActiveFilterItem({ name, onClear }) {
  return (
    <button className={classes.activeFilterItem} onClick={onClear}>
      <span>{name}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <g clipPath="url(#clip0_90_2221)">
          <path
            d="M6.00488 0C2.69117 0 0.00488281 2.68629 0.00488281 6C0.00488281 9.31371 2.69117 12 6.00488 12C9.31859 12 12.0049 9.31371 12.0049 6C12.0013 2.68777 9.31712 0.00358594 6.00488 0ZM8.00488 7.29349C8.20805 7.4805 8.22118 7.79681 8.03417 7.99999C7.84716 8.20317 7.53085 8.2163 7.32767 8.02929C7.3175 8.01994 7.30775 8.01016 7.29838 7.99999L6.00488 6.70699L4.71188 7.99999C4.51325 8.19183 4.19673 8.18632 4.00489 7.98769C3.81777 7.79393 3.81777 7.48676 4.00489 7.293L5.29789 6L4.00489 4.707C3.81305 4.50837 3.81856 4.19184 4.0172 4.00001C4.21095 3.81288 4.51813 3.81288 4.71188 4.00001L6.00488 5.29301L7.29838 4.00001C7.48538 3.79683 7.8017 3.7837 8.00488 3.97071C8.20805 4.15772 8.22118 4.47403 8.03417 4.67721C8.02482 4.68738 8.01505 4.69713 8.00488 4.70651L6.71187 6L8.00488 7.29349Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_90_2221">
            <rect width="12" height="12" fill="white" transform="translate(0.00488281)" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
}

function ActiveFilters({ isFilterPanel = true }) {
  const { stringRes } = useLocaleContext();
  const FILTER_TITLE_MAP = {
    cabinBaggage: stringRes["res.filter.title.baggage"],
    checkedBaggage: stringRes["res.filter.title.baggage"],
    stops: stringRes["res.filter.title.stops"],
    duration: stringRes["res.filter.title.duration"],
    stopover: stringRes["res.filter.title.duration"],
    departureStartTime: stringRes["res.filter.title.times"],
    departureEndTime: stringRes["res.filter.title.times"],
    returnStartTime: stringRes["res.filter.title.times"],
    returnEndTime: stringRes["res.filter.title.times"],
    airline: stringRes["res.filter.title.airlines"],
    airport: stringRes["res.filter.title.airports"],
    price: stringRes["res.filter.title.price"],
  };

  const dispatch = useDispatch();
  const filtersData = useSelector(selectFilters);

  const activeFilters = [
    ...new Set(
      Object.entries(filtersData)
        .filter((entry) => entry[1].isOn)
        .map((entry) => FILTER_TITLE_MAP[entry[0]])
    ),
  ];

  const onClearAllFilters = () => {
    dispatch(filterSortActions.clearAllFilters());
  };

  if (isFilterPanel) {
    return (
      <div className={`${classes.container} ${classes.activeFilters}`}>
        <h1>{`${activeFilters.length} ${stringRes["res.filter.active"]}`}</h1>
        <ClearAllFiltersButton onClearAll={onClearAllFilters} />
        <div className={classes.filters}>
          <ActiveFiltersList filters={activeFilters} filtersMap={FILTER_TITLE_MAP} />
        </div>
      </div>
    );
  } else {
    return <ActiveFiltersList filters={activeFilters} filtersMap={FILTER_TITLE_MAP} />;
  }
}

function ActiveFiltersList({ filters, filtersMap }) {
  const dispatch = useDispatch();

  const onClearFilter = (type) => {
    customLog("on clear filter:" + type);

    const filterGroup = Object.entries(filtersMap)
      .filter((e) => e[1] === type)
      .map((e) => e[0]);
    // customLog(filterGroup);
    filterGroup.forEach((type) =>
      dispatch(filterSortActions.clearFilter({ type: type }))
    );
  };

  return (
    <Fragment>
      {filters.map((filter) => (
        <ActiveFilterItem
          key={filter}
          name={filter}
          onClear={() => onClearFilter(filter)}
        />
      ))}
    </Fragment>
  );
}

export default ActiveFilters;
