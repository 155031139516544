import { resultsApi } from "src/services/api";
import store from "src/store";
import { updateTravelPackage } from "src/store/actions";
import { resetCartLoading, setCartItemLoading } from "src/store/cartOverview";
import { customLog, toTitleCase } from "src/utils/utils";

export function onUpdateFlightData(
  flightWsRef,
  searchQuery,
  mergeKey,
  dataProvider,
  onSuccess,
  onError
) {
  customLog(searchQuery);
  store.dispatch(setCartItemLoading({ keys: ["adultFare", "childFare", "infantFare"] }));
  resultsApi
    .getSpecificFlight(searchQuery, mergeKey, dataProvider)
    .then((res) => {
      const wsPath = res.data;
      customLog(wsPath);
      flightWsRef.current = new WebSocket(
        `wss://${window.SERVER_DATA.REACT_APP_API_URL}/search/websocket/${wsPath}`
      );

      flightWsRef.current.onopen = () => customLog("websocket open");
      flightWsRef.current.onclose = () => customLog("WebSocket connection closed");
      flightWsRef.current.onerror = (error) => customLog(`WebSocket error: ${error}`);
      flightWsRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.data?.flights?.[0]) {
          const updatedFlight = message.data.flights[0];
          updatedFlight.data_provider = message.data_provider;
          updatedFlight.segments.forEach((segment) => {
            segment.arr.city.title = toTitleCase(segment.arr.city.title);
            segment.dep.city.title = toTitleCase(segment.dep.city.title);
          });

          customLog(updatedFlight);
          if (flightWsRef.current) {
            flightWsRef.current.close();
            flightWsRef.current = null;
            store.dispatch(updateTravelPackage(updatedFlight));
            store.dispatch(resetCartLoading());
            if (onSuccess) onSuccess(updatedFlight, searchQuery);
          }
        }
      };
    })
    .catch((err) => {
      customLog(err);
      if (onError) onError();
    });
}
