import { customLog } from "src/utils/utils";
import classes from "./SearchFormText.module.css";
import { forwardRef, useEffect, useState } from "react";

const SearchFormText = forwardRef(function SearchFormText(
  { id, value, label, onClick, className },
  ref
) {
  const [hasInput, setHasInput] = useState(false);

  useEffect(() => {
    setHasInput(!!value);
  }, [value]);

  const setRef = (r) => {
    if (ref) {
      ref.passengers = r;
    }
  };

  return (
    <div
      ref={(r) => setRef(r)}
      className={`${classes.frame} ${className ?? ""}`}
      onClick={(e) => {
        customLog(e);
        onClick();
      }}>
      <label htmlFor={id} className={hasInput ? classes.smallText : classes.bigText}>
        <span onClick={(e) => e.stopPropagation()}>{label}</span>
      </label>
      <input id={id} type="text" readOnly value={value} hidden={!value} />
    </div>
  );
});

export default SearchFormText;
