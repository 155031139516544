import { useRef } from "react";
import { useSelector } from "react-redux";
import { BaggageProtectionIcon } from "src/components/UI/Icon/BaggageProtectionIcon";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { CATALOGUE_IDS } from "src/constants/services";
import { selectCatalogue, selectServicePrice } from "src/store/catalogue";
import { getDisplayPriceValue } from "src/utils/utils";
import { useLocaleContext } from "src/context/locale-context";
import classes from "../BaggageSelection.module.css";
import { BAGGAGE_TITLE_MAP, BAG_IMG_MAP, BAG_IMG_SIZE } from "../utils";
import { PopularTag } from "./PopularTag";

export function RegisteredBaggageOption({
  passengerId,
  serviceId,
  selected,
  isProtectionOn,
  isPopular,
  onSelect,
  isCabin,
  dimensions,
  description,
}) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const checked = selected === serviceId;
  const doubleLuggage = selected === CATALOGUE_IDS.r_baggage_double;
  const baggagePrice = useSelector((state) =>
    selectServicePrice(selectCatalogue(state), serviceId)
  );

  const ref = useRef();

  const onSelectHandler = (isChecked) => {
    if (isChecked && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
    onSelect(isChecked, serviceId);
  };

  return (
    <RadioButton
      checked={checked}
      id={"baggage-" + serviceId + passengerId}
      name={"baggage-selection-" + (isCabin ? "cabin" : "checked") + passengerId}
      className={`${classes.baggageType} ${checked ? classes.active : ""} ${
        baggagePrice === 0 ? classes.free : ""
      }`}
      onChange={onSelectHandler}>
      <h4 className={`${classes.bagPrice} ${baggagePrice === 0 ? classes.free : ""}`}>
        {baggagePrice > 0
          ? `${getDisplayPriceValue(baggagePrice)} ${currencySymbol}`
          : stringRes["booking.form.baggage.free"]}
      </h4>
      <div ref={ref} className={classes.optionContent}>
        {isPopular && <PopularTag text={stringRes["booking.form.baggage.popular"]} />}
        <h3 className={classes.optionTitle}>{stringRes[BAGGAGE_TITLE_MAP[serviceId]]}</h3>
        <p
          className={classes.optionDescription}
          style={
            !isCabin
              ? {
                  fontSize: "0.875rem",
                  fontFamily: "var(--font-semibold)",
                  fontWeight: "var(--semibold-w)",
                }
              : null
          }>
          {description}
        </p>
        <div className={classes.bagIcons}>
          {BAG_IMG_MAP[serviceId].map((imgSrc, i) => (
            <span className={classes.iconArea} key={serviceId + i}>
              <img style={{ width: `${BAG_IMG_SIZE[serviceId][i]}` }} alt="" src={imgSrc} />
              {isProtectionOn && checked && (
                <span className={classes.badge}>
                  <BaggageProtectionIcon className={classes.shield} />
                  {doubleLuggage && <BaggageProtectionIcon className={classes.shield2} />}
                </span>
              )}
              <p className={classes.dimensionsInfo}>{dimensions[i]}</p>
            </span>
          ))}
        </div>
      </div>
    </RadioButton>
  );
}
