import Button from "src/components/UI/Button/Button";
import classes from "./ResultsRightPanel.module.css";
import { useLocaleContext } from "src/context/locale-context";
import illustration from "src/assets/img/illustrations/illustr6.png";
import { useTenantContext } from "src/context/tenant-context";

function TrackPrices() {
  return <div className={classes.priceAlert}>...</div>;
}

function ContactAgent() {
  const { stringRes } = useLocaleContext();
  const { phone } = useTenantContext();
  return (
    <div className={classes.agent}>
      <div className={classes.image}>
        {/* <img src="https://placehold.co/120x120/d9d9d9/FFFFFF.png" alt="" /> */}
      </div>
      <h1>{stringRes["res.agent.title"]}</h1>
      <p className={classes.description}>
        <span>{stringRes["res.agent.text1"]}</span>
        <span>{stringRes["res.agent.text2"]}</span>
        <span>{phone}</span>
      </p>
    </div>
  );
}

function EmailExclusiveDeals() {
  const { stringRes } = useLocaleContext();
  return (
    <div className={classes.deals}>
      <div className={classes.image}>
        <img src={illustration} alt="mail box full of letters" />
      </div>
      <h1>{stringRes["res.email.subscribe.title"]}</h1>
      <Button secondary onClick={() => {}} name={stringRes["res.email.subscribe.button"]} />
    </div>
  );
}

export function ResultsRightPanel() {
  const { stringRes } = useLocaleContext();

  return (
    <section className="right-panel">
      <EmailExclusiveDeals />
      <div className={classes.disclaimer}>{stringRes["res.fee.disclaimer"]}</div>
      <ContactAgent />
    </section>
  );
}

export function LoadingResultsRightPanel() {
  return (
    <section className={`right-panel ${classes.loading}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </section>
  );
}
