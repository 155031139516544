import { nanoid } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { ERROR_MESSAGES, ERROR_STATUS_MESSAGE } from "src/assets/lang/errorMessages";
import { BILLING_TYPE, PASSENGER } from "src/constants";
import { BOOKING } from "src/constants/booking";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { bookingOrderApi } from "src/services/api";
import store from "src/store";
import { addPassenger, updatePassenger } from "src/store/actions";
import { bookingActions } from "src/store/booking";
import { orderActions } from "src/store/order";
import { BAGGAGE } from "src/utils/baggage-utils";
import {
  updateAdditionalServiceFromData,
  updateAncillaryServiceFromData,
  updateBillingFromData,
  updateContactFromData,
  updatePassengerFromData,
} from "src/utils/order-utils";
import {
  getSelectedLocale,
  setSelectedSessionTravelPackage,
  setSessionId,
} from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import { ADDITIONAL_SERVICE_MAP, PROTECTION_SERVICES } from "./booking-mappings";

async function getExistingBooking(correlationID, user, statusList = ["NEW", "DETAIL"]) {
  return bookingOrderApi
    .getOrderById(correlationID)
    .then((res) => {
      console.log(res.data);
      const { booking, updates } = res.data;
      const isAnonymous = !booking.userId;
      let doShowBookingToUser = isAnonymous || booking.userId === user?.uid;
      
      // if order done/wrong user clear session and clear travel package
      let redirectData;
      if (!statusList.includes(booking.status) || !doShowBookingToUser) {
        setSessionId("");
        setSelectedSessionTravelPackage("");
        redirectData = { path: "/" };
      }
      return { booking, updates, redirectData };
    })
    .catch((err) => {
      let lang = getSelectedLocale()?.split("_")[0] || "en";
      customLog(err);
      throw json({
        data: err,
        errorMessage: ERROR_MESSAGES[lang].booking,
        statusText: ERROR_STATUS_MESSAGE[lang].booking,
      });
    });
}

function restoreBookingData(updates) {
  let restoredData = {
    order: { additional: [], ancillary: { bags: [], seats: [] } },
    billing: { person: {}, company: {} },
    contact: {},
    passengers: [],
  };
  // and restore booking from the data
  updates.forEach((upd) => {
    switch (upd.command) {
      case BOOKING.UPDATE_CONTACT_INFO:
        updateContactFromData(restoredData, upd);
        break;
      case BOOKING.ADD_PASSENGER:
      case BOOKING.REMOVE_PASSENGER:
      case BOOKING.UPDATE_PASSENGER:
        updatePassengerFromData(restoredData, upd);
        break;
      case BOOKING.ADD_BILLING_INFO:
      case BOOKING.REMOVE_BILLING_INFO:
      case BOOKING.UPDATE_BILLING_INFO:
        updateBillingFromData(restoredData, upd);
        break;
      case BOOKING.ADD_SERVICE_ADDITIONAL:
      case BOOKING.REMOVE_SERVICE_ADDITIONAL:
        updateAdditionalServiceFromData(restoredData, upd);
        break;
      case BOOKING.ADD_SERVICE_ANCILLARY:
      case BOOKING.REMOVE_SERVICE_ANCILLARY:
        updateAncillaryServiceFromData(restoredData, upd);
        break;
      default:
        break;
    }
  });

  return restoredData;
}

function updateStoreWithContact(contact) {
  store.dispatch(bookingActions.updateContactData(contact));
  store.dispatch(bookingActions.changeNewsletterSubscription(contact.isSubscribedNewsletter));
}

function updateStoreWithBilling(billing) {
  if (billing.isRequired) {
    if (!store.getState().booking.billing.isVATRequired) {
      store.dispatch(bookingActions.toggleBillingRequired());
    }
    store.dispatch(
      bookingActions.changeBillingType(BILLING_TYPE[billing.isCompany ? "company" : "person"])
    );
    store.dispatch(
      billing.isCompany
        ? bookingActions.updateCompanyBillingData(billing.company)
        : bookingActions.updatePersonalBillingData(billing.person)
    );
  }
}

function updateStoreWithPassengers(passengers) {
  store.dispatch(bookingActions.resetPassengers());
  console.log(passengers);
  passengers.forEach((p) => {
    store.dispatch(addPassenger(p.ageGroup, p.id));
    store.dispatch(
      bookingActions.setPassengerServerIdentifier({
        id: p.id,
        passenger_id: p.passenger_id,
      })
    );
    store.dispatch(updatePassenger(p.id, p.ageGroup, { ...p }));
  });
  if (passengers.length === 0) {
    store.dispatch(addPassenger(PASSENGER.adult, nanoid()));
  }
}

function updateStoreWithAdditionalServices(additional) {
  additional.forEach((s) => {
    const matchedService = ADDITIONAL_SERVICE_MAP.find((m) => m[0] === s.name);
    if (matchedService) {
      const [key, serviceId, updateAction] = matchedService;
      store.dispatch(
        updateAction(
          key === "BAG_PROTECTION"
            ? { serviceId, passengerId: s.passengerId, backend_id: s.backend_id }
            : { serviceId, backend_id: s.backend_id }
        )
      );
    }
  });
}

function updateWithBags(bags, travelPackage) {
  const singleBags = travelPackage.baggage[BAGGAGE.checked].filter((bag) => bag.piece === 1);
  singleBags.sort(
    (a, b) =>
      a.weight - b.weight ||
      a.r_baggage_price.total_price_per_person.price -
        b.r_baggage_price.total_price_per_person.price
  );

  bags.forEach((b) => {
    let payload = { serviceId: "", passengerId: b.passengerId, backend_id: b.backend_id };
    if (b.id === "r_baggage") {
      if (b.piece === 1) {
        const sbIdx = singleBags.findIndex(
          (sb) =>
            sb.weight === b.weight &&
            sb.r_baggage_price.total_price_per_person.price ===
              b.data.r_baggage_price.total_price_per_person.price
        );
        payload.serviceId =
          sbIdx === 0
            ? CATALOGUE_IDS.r_baggage_def
            : sbIdx === 1
            ? CATALOGUE_IDS.r_baggage_larger
            : "";
      } else if (b.piece === 2) {
        payload.serviceId = CATALOGUE_IDS.r_baggage_double;
      }
      if (payload.serviceId) {
        store.dispatch(orderActions.changeCheckedBaggageType(payload));
      }
    } else {
      const serviceMap = {
        pi_baggage: CATALOGUE_IDS.pi_baggage,
        cob_baggage: CATALOGUE_IDS.cob_baggage,
        cob_baggage_upgrade: CATALOGUE_IDS.cob_baggage_upgrade,
      };
      payload.serviceId = serviceMap[b.id];
      if (payload.serviceId) {
        store.dispatch(orderActions.changeCabinBaggageType(payload));
      }
    }
  });
}

function updateWithSeats(seats) {
  seats.forEach((s) => {
    store.dispatch(
      orderActions.changeSeatType({
        segment: s.segment,
        serviceId: CATALOGUE_IDS.seats[s.id],
        airline: s.airline,
        backend_id: s.backend_id,
      })
    );
  });
}

function updateStoreWithAncillaryServices(ancillary, travelPackage) {
  updateWithBags(ancillary.bags, travelPackage);
  updateWithSeats(ancillary.seats);
}

function updateStoreWithExplicitlyRefusedServices(updates) {
  const groupedAddedServices = Object.groupBy(
    updates
      .filter((upd) => upd.command === BOOKING.ADD_SERVICE_ADDITIONAL)
      .map((upd) => {
        return { ...upd.payload, cmd: upd.command };
      }),
    ({ name }) => name
  );

  const groupedRefusedServices = Object.groupBy(
    updates
      .filter((upd) => upd.command === BOOKING.REMOVE_SERVICE_ADDITIONAL)
      .map((upd) => {
        return {
          explicit_refuse: upd.explicit_refuse,
          name: upd.payload.name,
          cmd: upd.command,
        };
      }),
    ({ name }) => name
  );

  Object.entries(PROTECTION_SERVICES).forEach(([serviceName, [action, key]]) => {
    const serviceWasAddedInThePast = groupedAddedServices[serviceName]?.length > 0;
    const serviceExplicitlyRefused = groupedRefusedServices[serviceName]?.length === 1;
    const currentServiceId = store.getState().order[key].serviceId;

    if ((serviceWasAddedInThePast || serviceExplicitlyRefused) && !currentServiceId) {
      store.dispatch(action(SERVICE_TYPE.REFUSED));
    }
  });
}

export const RestoreUtil = {
  getExistingBooking,
  restoreBookingData,
  updateStoreWithAdditionalServices,
  updateStoreWithAncillaryServices,
  updateStoreWithBilling,
  updateStoreWithContact,
  updateStoreWithExplicitlyRefusedServices,
  updateStoreWithPassengers,
};
