import { Outlet, ScrollRestoration } from "react-router-dom";
import { ModalContextManager } from "src/context/modal-stack-context";
import { PriceExpiryManager } from "src/context/refresh-price-context";
import { LocaleContextManager } from "src/context/locale-context";
import { MAX_SCREEN_WIDTH_MOBILE } from "src/constants";
import { useMediaQuery } from "react-responsive";
import DeviceContext from "src/context/device-context";
import { ToastContainer } from "react-toastify";
import { GlobalLoadingProvider } from "src/context/loading-context";
import { TenantContextManager } from "src/context/tenant-context";

function RootLayout() {
  const isMobile = useMediaQuery({
    maxWidth: MAX_SCREEN_WIDTH_MOBILE,
    // maxDeviceWidth: MAX_DEVICE_WIDTH_MOBILE,
  });

  const DOMAIN_TENANT_ID = window.SERVER_DATA.REACT_APP_TENANT_NAME;
  return (
    <TenantContextManager tenantID={DOMAIN_TENANT_ID}>
      <LocaleContextManager companyId={DOMAIN_TENANT_ID}>
        <PriceExpiryManager>
          <DeviceContext.Provider value={{ isMobile: isMobile }}>
            <GlobalLoadingProvider>
              <ModalContextManager>
                <div id="root-container">
                  <Outlet />
                </div>
                <ToastContainer
                  position="bottom-right"
                  hideProgressBar
                  draggable={false}
                  theme="colored"
                />
                <ScrollRestoration />
              </ModalContextManager>
            </GlobalLoadingProvider>
          </DeviceContext.Provider>
        </PriceExpiryManager>
      </LocaleContextManager>
    </TenantContextManager>
  );
}

export default RootLayout;
