import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import hamburger from "src/assets/img/hamburger.png";
import iconHeart from "src/assets/img/heartIcon.png";
import iconHeartFilled from "src/assets/img/heartIconFilled.png";
import iconUser from "src/assets/img/userIcon.png";
import logo from "src/assets/logo/logoLV.svg";
import ButtonWithIcon from "src/components/UI/ButtonWithIcon/ButtonWithIcon";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import GuestMenu from "src/components/shared/menu/GuestMenu/GuestMenu";
import UserMenu from "src/components/shared/menu/UserMenu/UserMenu";
import { useAuth } from "src/context/auth-context";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import { filterSortActions } from "src/store/filter-sort";
import { customLog } from "src/utils/utils";
import classes from "./TopNavigation.module.css";

const TopNavigationItems = [
  { name: "main.nav.airtickets", to: "/" },
  { name: "main.nav.cheapflights", to: "/cheapflights" },
  { name: "main.nav.hotels", to: "/hotels" },
  { name: "main.nav.insurance", to: "/insurance" },
  { name: "main.nav.business", to: "/business" },
];

export function TopRightMenu({ withHeartButton }) {
  const { isMobile } = useDeviceContext();
  const navigate = useNavigate();
  const { currentLocale, stringRes } = useLocaleContext();
  const { isAuthenticated, user, logout } = useAuth();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isFavorited, setFavorited] = useState(false);
  const { openModal } = useModalContext();

  const IMG_RESIZE_FACTOR = isMobile ? 1.25 : 1;

  const loginHandler = () => {
    openModal(MODAL_TYPE.loginOptions);
    setMenuOpen(false);
  };

  const logoutHandler = () => {
    logout();
    setMenuOpen(false);
  };

  const signupHandler = () => {
    openModal(MODAL_TYPE.createAccount);
    setMenuOpen(false);
  };

  const showUserMenu = isAuthenticated && isMenuOpen;
  const showGuestMenu = !isAuthenticated && isMenuOpen;

  const onClickFavoriteHandler = () => {
    setFavorited((prev) => !prev);
  };

  const onClickRegionalSettings = () => {
    customLog("regional settings clicked");
    openModal(MODAL_TYPE.regionalSettings);
  };

  const rightMenuItems = [
    {
      props: {
        img: isFavorited ? iconHeartFilled : iconHeart,
        imgWidth: 23 * IMG_RESIZE_FACTOR,
        imgHeight: 22 * IMG_RESIZE_FACTOR,
      },
      onClick: onClickFavoriteHandler,
      name: "favorite",
    },
    {
      props: {
        img: require(`src/assets/img/flags/${currentLocale}.png`),
        imgWidth: 16 * IMG_RESIZE_FACTOR,
        imgHeight: 16 * IMG_RESIZE_FACTOR,
      },
      text: isMobile ? null : "EUR",
      name: "currency",
      onClick: onClickRegionalSettings,
    },
    {
      props: isAuthenticated
        ? {
            imgEl: ({ imageClass }) => (
              <img
                className={imageClass}
                style={{ borderRadius: "100%", maxHeight: 19 }}
                alt="user"
                src={user.photoURL ?? iconUser}
              />
            ),
          }
        : {
            img: iconUser,
            imgWidth: 15,
            imgHeight: 15,
          },
      text: isAuthenticated
        ? isMobile
          ? null
          : user.displayName || "Traveler"
        : stringRes["main.nav.login"],
      name: "user",
      onClick: isAuthenticated ? () => navigate("/dashboard") : loginHandler,
    },
    {
      props: {
        img: hamburger,
        imgWidth: 14 * IMG_RESIZE_FACTOR,
        imgHeight: 12 * IMG_RESIZE_FACTOR,
      },
      onClick: () => setMenuOpen(true),
      name: "menu",
    },
  ].splice(withHeartButton ? 0 : 1);

  return (
    <Fragment>
      {showGuestMenu && (
        <GuestMenu
          onClose={() => setMenuOpen(false)}
          onLogin={loginHandler}
          onSignup={signupHandler}
        />
      )}
      <nav className={`${classes.menuRight} ${classes.container}`}>
        {rightMenuItems.map((menuItem) => (
          <ButtonWithIcon
            key={menuItem.name}
            name={menuItem.name}
            {...menuItem.props}
            onClick={menuItem.onClick}>
            {menuItem.text}
          </ButtonWithIcon>
        ))}
        {!isMobile && showUserMenu && (
          <UserMenu onClose={() => setMenuOpen(false)} onLogout={logoutHandler} />
        )}
      </nav>
    </Fragment>
  );
}

function TopNavigation() {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const dispatch = useDispatch();

  const onClickLogoHandler = () => {
    dispatch(filterSortActions.clearAllFilters());
  };

  return (
    <header
      id="main-app-top-nav"
      className={`${classes.menu} ${isMobile ? classes.mobile : classes.desktop}`}>
      <PaddedView>
        <nav className={classes.menuLeft}>
          <Link to={"/"} onClick={onClickLogoHandler}>
            <img className={classes.logo} alt="logo" src={logo} />
          </Link>
          {!isMobile &&
            TopNavigationItems.map((item) => (
              <NavLink
                key={item.name}
                to={item.to}
                className={({ isActive }) => (isActive ? classes.active : "")}>
                {stringRes[item.name]}
              </NavLink>
            ))}
        </nav>
        <TopRightMenu withHeartButton={!isMobile} />
      </PaddedView>
    </header>
  );
}

export default TopNavigation;
