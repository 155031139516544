import { Fragment, useContext } from "react";
import classes from "./LocationItemGroup.module.css";
import { PopularCityItem } from "./PopularCityItem";
import { GroupHeading } from "../AirportSelection/AirportSelection";
import { useDispatch } from "react-redux";
import { ModalElementContext } from "src/context/modal-element-context";
import { searchDataActions } from "src/store/search";
import { customLog } from "src/utils/utils";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { useLocaleContext } from "src/context/locale-context";

export function PopularCityItemGroup({ title, items, isDeparture }) {
  const dispatch = useDispatch();
  const { onClose, targetTripId, dispatchLocalState, isResultsModal } =
    useContext(ModalElementContext);
  const { stringRes, currentLocale } = useLocaleContext();

  const onSelectHandler = (location) => {
    customLog("onSelectHandler DISPATCH " + location.city);
    let lang = currentLocale.split("_")[0].toUpperCase();
    if (isResultsModal) {
      dispatchLocalState({
        type: "UPDATE_TRIP",
        id: targetTripId,
        payload: {
          [isDeparture ? "srcLocation" : "destLocation"]: {
            city: location.city,
            iata: location.iata,
            cityIata: location.cityIata,
            isCity: true,
            names: location.names,
          },
        },
      });
      dispatch(
        searchDataActions.updateUserInputTrip({
          tripId: targetTripId,
          input: {
            [isDeparture ? "srcLocation" : "destLocation"]: location.names[lang],
            [isDeparture ? "srcIata" : "destIata"]: location.iata,
          },
        })
      );
    } else {
      const payload = {
        tripId: targetTripId,
        location: location,
        isCity: true,
        lang: lang,
      };
      if (isDeparture) {
        dispatch(searchDataActions.updateSrcLocation(payload));
      } else {
        dispatch(searchDataActions.updateDestLocation(payload));
      }
    }
    onClose();
  };

  return (
    <Fragment>
      <GroupHeading title={title} />

      {!items || items.length === 0 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {items && items.length === 0 && <DotPulseLoader opacity={0.4} />}
          {items === null && (
            <span style={{ color: "var(--ui-5)" }}>
              {stringRes["airport.search.popular.none"]}
            </span>
          )}
        </div>
      ) : (
        <ul className={`${classes.cityList} ${classes.container}`}>
          {items.map((item) => (
            <PopularCityItem key={item.city} item={item} onSelectCity={onSelectHandler} />
          ))}
        </ul>
      )}
    </Fragment>
  );
}
