import * as jose from "jose";
import { BOOKING } from "src/constants/booking";
import { customLog } from "./utils";

export function unpackOrderToken(orderToken) {
  const decodedOrderData = jose.decodeJwt(orderToken);
  return decodedOrderData;
}

export function updateContactFromData(ctx, update) {
  ["email", "telCode", "tel", "isSubscribedNewsletter"].forEach(
    (key) => (ctx.contact[key] = update.payload[key])
  );
}

export function updatePassengerFromData(ctx, update) {
  const paxId = update.payload.id;
  if (update.command === BOOKING.ADD_PASSENGER) {
    ctx.passengers.push({ ...update.payload });
  } else if (update.command === BOOKING.UPDATE_PASSENGER) {
    const targetIndex = ctx.passengers.findIndex((p) => p.id === paxId);
    if (targetIndex !== -1) {
      [
        "ageGroup",
        "passenger_id",
        "name",
        "surname",
        "nationality",
        "gender",
        "dob",
        "main",
      ].forEach((key) => (ctx.passengers[targetIndex][key] = update.payload[key]));
    }
  } else if (update.command === BOOKING.REMOVE_PASSENGER) {
    // customLog("REMOVE PAX: " + update.payload.passenger_client_id);
    const targetIndex = ctx.passengers.findIndex(
      (p) =>
        p.passenger_id === update.payload.passenger_id ||
        p.id === update.payload.passenger_client_id
    );
    if (targetIndex !== -1) {
      ctx.passengers.splice(targetIndex, 1);
    }
  }
}

export function updateBillingFromData(ctx, update) {
  if (
    update.command === BOOKING.ADD_BILLING_INFO ||
    update.command === BOOKING.UPDATE_BILLING_INFO
  ) {
    ctx.billing.isRequired = true;
    ctx.billing.isCompany = update.payload.isCompany;
    if (update.payload.isCompany) {
      ctx.billing.person = {};
      ["companyName", "companyID", "VATNumber", "address", "country"].forEach(
        (key) => (ctx.billing.company[key] = update.payload[key])
      );
    } else {
      ctx.billing.company = {};
      ["name", "surname", "address", "country"].forEach(
        (key) => (ctx.billing.person[key] = update.payload[key])
      );
    }
  } else if (update.command === BOOKING.REMOVE_BILLING_INFO) {
    ctx.billing = { isRequired: false, company: {}, person: {} };
  }
}

export function updateAdditionalServiceFromData(ctx, update) {
  if (update.command === BOOKING.ADD_SERVICE_ADDITIONAL) {
    ctx.order.additional.push({
      id: update.payload.id,
      name: update.payload.name,
      passengerId: update.payload.passenger_client_id,
      backend_id: update.payload.backend_id,
      data: { ...update.payload },
    });
  } else if (update.command === BOOKING.REMOVE_SERVICE_ADDITIONAL) {
    const idx = ctx.order.additional.findIndex((s) => s.id === update.payload.id);
    if (idx !== -1) {
      ctx.order.additional.splice(idx, 1);
    }
  }
}

export function updateAncillaryServiceFromData(ctx, update) {
  if (update.command === BOOKING.ADD_SERVICE_ANCILLARY) {
    if (update.payload.type === "baggage") {
      ctx.order.ancillary.bags.push({
        id: update.payload.id,
        type: "baggage",
        backend_id: update.payload.backend_id,
        passengerId: update.payload.passenger_client_id,
        piece: update.payload.data.piece,
        weight: update.payload.data.weight,
        data: { ...update.payload.data },
      });
    } else if (update.payload.type === "seats") {
      ctx.order.ancillary.seats.push({
        type: "seats",
        segment: update.payload.segment,
        airline: update.payload.airline,
        id: update.payload.id,
        backend_id: update.payload.backend_id,
        data: { ...update.payload.data },
      });
    }
  } else if (update.command === BOOKING.REMOVE_SERVICE_ANCILLARY) {
    if (update.payload.type === "baggage") {
      const idx = ctx.order.ancillary.bags.findIndex(
        (s) =>
          s.passengerId === update.payload.passenger_client_id && s.id === update.payload.id
      );
      if (idx !== -1) {
        ctx.order.ancillary.bags.splice(idx, 1);
      }
    } else if (update.payload.type === "seats") {
      const idx = ctx.order.ancillary.seats.findIndex(
        (s) => s.segment === update.payload.segment
      );
      ctx.order.ancillary.seats.splice(idx, 1);
    }
  }
}
