import SidePanel from "src/components/shared/panel/SidePanel/SidePanel";
import classes from "./GuestMenu.module.css";
import fbLogoMobile from "src/assets/img/fbLogo2.png";
import instaLogoMobile from "src/assets/img/intagramLogo2.png";
import twitterLogoMobile from "src/assets/img/twitterLogo2.png";
import Button from "src/components/UI/Button/Button";
import ExpandingNavList from "src/components/shared/navigation/ExpandingNavList/ExpandingNavList";
import { Fragment, useState } from "react";
import { guestMenuItems } from "./guestMenuItems";
import { LOCALE_TYPE, MODAL_ANIMATION_TIME, PROJECT_NAME } from "src/constants";
import Modal from "src/components/shared/modal/Modal/Modal";
import { useDeviceContext } from "src/context/device-context";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { useLocaleContext } from "src/context/locale-context";
import { createPortal } from "react-dom";
import { Markup } from "interweave";
import { motion } from "framer-motion";

const portalElement = document.getElementById("sidebar");

export function SocialMediaGroup() {
  const logoList = {
    facebook: { alt: "facebook", img: fbLogoMobile },
    instagram: { alt: "instagram", img: instaLogoMobile },
    twitter: { alt: "twitter", img: twitterLogoMobile },
  };
  return (
    <div className={`${classes.container} ${classes.imageGroup}`}>
      <a href="/">
        <img width={15} alt={logoList.facebook.alt} src={logoList.facebook.img} />
      </a>
      <a href="/">
        <img width={15} alt={logoList.twitter.alt} src={logoList.twitter.img} />
      </a>
      <a href="/">
        <img width={15} alt={logoList.instagram.alt} src={logoList.instagram.img} />
      </a>
    </div>
  );
}

const LANGUAGE_STR_MAP = {
  [LOCALE_TYPE.GB]: "regional.language.english",
  [LOCALE_TYPE.LV]: "regional.language.latvian",
  [LOCALE_TYPE.LT]: "regional.language.lithuanian",
  [LOCALE_TYPE.EE]: "regional.language.estonian",
  [LOCALE_TYPE.RU]: "regional.language.russian",
};

function GuestMenuContent({ onClose, onSignup, onLogin, mobile }) {
  const { stringRes, currencySymbol, currency, currentLocale } = useLocaleContext();
  return (
    <Fragment>
      <CloseWindowButton onClick={onClose} />
      <div
        className={`${classes.container} ${classes.guestMenu} ${
          mobile ? classes.mobile : ""
        }`}>
        <div className={classes.banner}>
          <img alt="banner" src="http://via.placeholder.com/280x140" />
        </div>
        <div className={`${classes.container} ${classes.middle}`}>
          <p className={classes.container}>
            <span>
              <Markup
                content={stringRes["main.nav.sidebar.text"].replace(
                  "##PROJECT_NAME##",
                  PROJECT_NAME
                )}
              />
            </span>
          </p>
          <Button
            name={stringRes["res.modal.signin.button"]}
            opts={{ fontSize: "var(--fmob-size-18px)" }}
            onClick={onLogin}
            primary
            wide
          />
          <motion.button
            whileTap={{ scale: 0.97, color: "var(--primary-link-color)" }}
            className={classes.textLink}
            onClick={onSignup}>
            {stringRes["main.nav.account.create"]}
          </motion.button>
        </div>
        <div className={classes.infolinks}>
          {!mobile && (
            <span className={classes.container}>
              <img
                alt=""
                className={classes.icon}
                src={require(`src/assets/img/flags/${currentLocale}.png`)}
              />
              <span>
                {stringRes[LANGUAGE_STR_MAP[currentLocale]]} - {currency} {currencySymbol}
              </span>
            </span>
          )}
          <ExpandingNavList items={guestMenuItems} />
        </div>
        {/* <SocialMediaGroup /> */}
      </div>
    </Fragment>
  );
}

function MobileGuestMenu({ onLogin, onSignup, onClose, isClosing }) {
  return (
    <Modal isClosing={isClosing} fullscreen>
      <GuestMenuContent onSignup={onSignup} onLogin={onLogin} onClose={onClose} mobile />
    </Modal>
  );
}

function DesktopGuestMenu({ onLogin, onSignup, onClose, isClosing }) {
  const guestMenu = (
    <SidePanel isClosing={isClosing} opts={{ padding: "30px" }}>
      <GuestMenuContent onSignup={onSignup} onLogin={onLogin} onClose={onClose} />
    </SidePanel>
  );

  return <Fragment>{createPortal(guestMenu, portalElement)}</Fragment>;
}

function GuestMenu(props) {
  const { isMobile } = useDeviceContext();
  const [isMenuClosing, setIsMenuClosing] = useState(false);

  const onCloseHandler = () => {
    if (!isMobile) {
      setTimeout(props.onClose, MODAL_ANIMATION_TIME);
    } else {
      props.onClose();
    }
    setIsMenuClosing(true);
  };

  const menuProps = {
    onLogin: props.onLogin,
    onSignup: props.onSignup,
    onClose: onCloseHandler,
    isClosing: isMenuClosing,
  };

  return (
    <Fragment>
      {isMobile ? (
        <MobileGuestMenu {...menuProps} />
      ) : (
        <DesktopGuestMenu {...menuProps} />
      )}
    </Fragment>
  );
}

export default GuestMenu;
