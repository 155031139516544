import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./ResetLinkSent.module.css";
import { ModalElementContext } from "src/context/modal-element-context";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import Modal from "../Modal/Modal";
import DesktopModal from "../Modal/DesktopModal";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { Fragment } from "react";
import illustration from "src/assets/img/illustrations/illustr6.png";

export function ResetLinkSent({ onClose, state }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  const innerContent = (
    <Fragment>
      <div className={classes.image}>
        <img src={illustration} alt="mail box full of letters" />
      </div>
      <h1>{stringRes["reset.password.modal.title"]}</h1>
      <p>{stringRes["reset.password.modal.desc"]}</p>
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal
        classBody={classes.body}
        isClosing={state.isClosing}
        onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>
          <button type="button" className={classes.close} onClick={onClose}>
            <XIconDesktop color={"#4F5E71"} />
          </button>
          {innerContent}
        </div>
      </DesktopModal>
    );
  }
}
