import { useState } from "react";
import Button from "src/components/UI/Button/Button";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { DownloadIcon } from "src/components/UI/Icon/Icons";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import DesktopModal from "src/components/shared/modal/Modal/DesktopModal";
import Modal from "src/components/shared/modal/Modal/Modal";
import { useDeviceContext } from "src/context/device-context";
import { ModalElementContext } from "src/context/modal-element-context";
import classes from "./Document.module.css";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";

function DocumentFrame({
  modalId,
  state,
  children,
  title,
  onClose,
  onSave,
  onPrint,
  docState,
}) {
  const { isMobile } = useDeviceContext();

  const buttonBar = (
    <div className={classes.buttons}>
      <Button name="Close" secondary onClick={onClose} />
      <Button type="button" opts={{ gridColumn: "3" }} name="Print" onClick={onPrint} />
      <Button
        disabled={docState.isDownloading}
        onClick={onSave}
        opts={{ gridColumn: "4" }}
        name={
          <>
            <div style={{ maxWidth: "1.3125rem", display: "flex", justifyContent: "center" }}>
              {docState.isDownloading ? (
                <DotPulseLoader scale={isMobile ? 0.9 : 0.8} />
              ) : (
                <DownloadIcon size={21} />
              )}
            </div>
            {!isMobile && "Download"}
          </>
        }
      />
    </div>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal fullscreen isClosing={state.isClosing}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.docframe}`}>
            <div className={classes.title}>{title}</div>
            {children}
            {buttonBar}
          </div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal
        classBody={classes.body}
        modalClass={classes.modal}
        modalId={modalId}
        isClosing={state.isClosing}
        onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.docframe}`}>
          <button type="button" className={classes.close} onClick={onClose}>
            <XIconDesktop color="#4F5E71" />
          </button>
          <div className={classes.title}>{title}</div>
          {children}
          {buttonBar}
        </div>
      </DesktopModal>
    );
  }
}

export default DocumentFrame;
