import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalElementContext } from "src/context/modal-element-context";
import { TRIP_TYPE } from "src/constants";
import { useDeviceContext } from "src/context/device-context";
import { airportsApi } from "src/services/api";
import { searchDataActions } from "src/store/search";
import { isTodayOrLater } from "src/utils/date-utils";
import { getSavedTripSearches } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import classes from "./AirportSelection.module.css";
import { AirportSelectionDesktop } from "./AirportSelectionDesktop";
import { AirportSelectionMobile } from "./AirportSelectionMobile";
import { useLocaleContext } from "src/context/locale-context";

export const userInputTag = (string, userInput) => {
  return string.replace("##USER_INPUT##", userInput);
};

const MAX_DEPARTURE_CITIES = 5;
const MAX_ARRIVAL_CITIES = 10;

const buildAirportQuery = (keyword, cityCode, airportCode) => {
  const params = [];
  if (keyword) {
    params.push("q=" + encodeURIComponent(keyword));
  } else if (cityCode) {
    params.push("city=" + encodeURIComponent(cityCode));
  } else if (airportCode) {
    params.push("airport=" + airportCode);
  }
  customLog(params.join("&").toLowerCase());
  return params.join("&").toLowerCase();
};

const removeKeywordExtraWhitespace = (keyword) => {
  const kwList = keyword.split(" ");
  let trimmedKwList = [];
  for (let i = 0; i < kwList.length; i++) {
    const trimmedKeyword = kwList[i].trim();
    if (trimmedKeyword) {
      trimmedKwList.push(trimmedKeyword);
    }
  }
  return trimmedKwList.join(" ");
};

export function AirportSelection({ isDeparture }) {
  const { onClose, targetTripId, isResultsModal, dispatchLocalState } =
    useContext(ModalElementContext);
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();
  const { currentLocale } = useLocaleContext();

  const [popularCityItems, setPopularCityItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [savedSearches, setSavedSearches] = useState([]);
  const [lastSearchedQuery, setLastSearchedQuery] = useState("");
  const [hasCustomInput, setHasCustomInput] = useState(false);

  const fieldInputData = useSelector((state) =>
    state.search.userInput.trips.find((item) => item.id === targetTripId)
  );
  const key = isDeparture ? "srcLocation" : "destLocation";
  const fieldInput = fieldInputData[key];

  const onClearHandler = () => {
    let lang = currentLocale.split("_")[0].toUpperCase();
    if (isResultsModal) {
      if (isDeparture) {
        dispatch(searchDataActions.clearUserInputSrcLocation({ tripId: targetTripId }));
        dispatchLocalState({
          type: "UPDATE_TRIP",
          id: targetTripId,
          payload: {
            srcLocation: { city: "", iata: "", isCity: false, cityIata: "", names: [] },
          },
        });
      } else {
        dispatch(searchDataActions.clearUserInputDestLocation({ tripId: targetTripId }));
        dispatchLocalState({
          type: "UPDATE_TRIP",
          id: targetTripId,
          payload: {
            destLocation: { city: "", iata: "", isCity: false, cityIata: "", names: [] },
          },
        });
      }
    } else {
      const payload = {
        tripId: targetTripId,
        location: { city: "", iata: "", cityIata: "", names: [] },
        isCity: false,
        lang: lang,
      };
      if (isDeparture) {
        dispatch(searchDataActions.updateSrcLocation(payload));
      } else {
        dispatch(searchDataActions.updateDestLocation(payload));
      }
    }
    setNotFound(false);
    setLastSearchedQuery("");
    setFilterItems([]);
  };

  const onChangeInputHandler = (input) => {
    setHasCustomInput(true);
    if (input) {
      const payload = {
        tripId: targetTripId,
      };
      payload.input = { [key]: input, [isDeparture ? "srcIata" : "destIata"]: "" };
      dispatch(searchDataActions.updateUserInputTrip(payload));
    } else {
      onClearHandler();
    }
  };

  useEffect(() => {
    const getPopularAirports = isDeparture
      ? airportsApi.getPopularDestinationAirports
      : airportsApi.getPopularArrivalAirports.bind(null, fieldInputData.srcIata);
    const limit = isDeparture ? MAX_DEPARTURE_CITIES : MAX_ARRIVAL_CITIES;

    getPopularAirports()
      .then((response) => {
        const transformedPlaces = response.data.map((p) => {
          const [city, country] = p.name.split(",").map((w) => w.trim());
          return {
            cityIata: p.code,
            city,
            country,
            localizedCity: city,
            localizedCountry: country,
          };
        });
        setPopularCityItems(transformedPlaces.slice(0, limit));
      })
      .catch((error) => {
        customLog(error);
        if (error.response.status === 404) {
          setPopularCityItems(null);
        }
      });

    let savedSearches = getSavedTripSearches();
    savedSearches = savedSearches.filter(
      (s) =>
        isTodayOrLater(s.startDate) &&
        ((s.tripType === TRIP_TYPE.oneway && !s.endDate) ||
          (s.tripType === TRIP_TYPE.roundtrip && s.endDate && isTodayOrLater(s.endDate)))
    );
    setSavedSearches(savedSearches); // from local storage
  }, [fieldInputData.srcIata, isDeparture]);

  useEffect(() => {
    if (!hasCustomInput && fieldInput) {
      customLog("***request - kw***");
      onRequestHandler(fieldInput);
      setHasCustomInput(true);
    } else {
      const timeoutId = setTimeout(() => {
        if (hasCustomInput && fieldInput.length > 2) {
          customLog("FILTERING CUSTOM");
          onRequestHandler(fieldInput);
        }
        if (hasCustomInput && !fieldInput) {
          onClearHandler();
        }
        setHasCustomInput(true);
      }, 250);
      return () => clearTimeout(timeoutId);
    }
  }, [fieldInput]);

  const onCloseWithoutSaving = () => {
    onClose();
  };

  const onRequestHandler = async (keyword) => {
    customLog(keyword);
    if (keyword) {
      keyword = removeKeywordExtraWhitespace(keyword);
    }

    let results;
    const currentQuery = buildAirportQuery(keyword);
    customLog(currentQuery);
    const isDifferentQuery = currentQuery !== lastSearchedQuery;

    if (isDifferentQuery) {
      setLastSearchedQuery(currentQuery);
    }

    if (isDifferentQuery) {
      customLog("***new request***");
      airportsApi
        .getAirports(currentQuery, "EN")
        .then((response) => {
          results = response.data;
        })
        .catch((err) => {
          console.log("Error Occured: " + err);
          results = [];
        })
        .finally(() => {
          setFilterItems(results);
          setNotFound(results.length === 0);
        });
    }
  };

  const props = {
    userInput: fieldInput,
    popularCityItems: popularCityItems,
    filterItems: filterItems,
    isUserFiltered: !!lastSearchedQuery,
    notFound: notFound,
    savedSearches: savedSearches,
    isDeparture: isDeparture,
    onClose: onCloseWithoutSaving,
  };

  return (
    <Fragment>
      {isMobile && (
        <AirportSelectionMobile
          {...props}
          onChangeInput={onChangeInputHandler}
          onClearInput={onClearHandler}
        />
      )}
      {!isMobile && <AirportSelectionDesktop {...props} />}
    </Fragment>
  );
}

export function GroupHeading({ title }) {
  return (
    <div className={classes.container}>
      <h2>{title}</h2>
    </div>
  );
}
