import { nanoid } from "@reduxjs/toolkit";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DocumentView from "src/components/features/booking/Documents/DocumentView";
import ResumeBookingModal from "src/components/features/booking/ResumeBookingModal/ResumeBookingModal";
import ServiceDescription from "src/components/features/booking/ServiceDescription/ServiceDescription";
import UpdateBookingModal from "src/components/features/booking/UpdateBookingModal/UpdateBookingModal";
import FlightDetails from "src/components/features/results/FlightDetails/FlightDetails";
import GetFreshPricesModal from "src/components/features/results/GetFreshPrices/GetFreshPrices";
import SignInPopup from "src/components/features/results/SignInPopup/SignInPopup";
import { LoginByEmail } from "src/components/shared/modal/Login/LoginByEmail";
import LoginOptions from "src/components/shared/modal/Login/LoginOptions";
import { ResetLinkSent } from "src/components/shared/modal/Login/ResetLinkSent";
import RegionalSettings from "src/components/shared/modal/RegionalSettings/RegionalSettings";
import { RegisterByEmail } from "src/components/shared/modal/Registration/Registration";
import { VerifyNewEmail } from "src/components/shared/modal/Registration/VerifyNewEmail";
import ShareItinerary from "src/components/shared/modal/ShareItinerary/ShareItinerary";
import { LOGIN_VENDOR } from "src/constants";

export const MODAL_TYPE = {
  documentView: "documentViewModal",
  serviceDetails: "serviceDetailsModal",
  flightDetails: "flightDetailsModal",
  refreshPrice: "refreshPriceModal",
  staleBooking: "staleBookingModal",
  resumeBooking: "resumeBookingModal",
  regionalSettings: "regionalSettingsModal",
  shareOptions: "shareOptionsModal",
  loginOptions: "loginOptionsModal",
  signInOffer: "signInOfferModal",
  createAccount: "createAccountModal",
  verifyEmail: "verifyEmailModal",
  resetLinkSent: "resetLinkSentModal",
  [LOGIN_VENDOR.apple]: LOGIN_VENDOR.apple,
  [LOGIN_VENDOR.facebook]: LOGIN_VENDOR.facebook,
  [LOGIN_VENDOR.google]: LOGIN_VENDOR.google,
  [LOGIN_VENDOR.email]: LOGIN_VENDOR.email,
};

const MODAL_TYPE_COMPONENT = {
  [MODAL_TYPE.documentView]: { component: DocumentView, isStandalone: true },
  [MODAL_TYPE.serviceDetails]: { component: ServiceDescription },
  [MODAL_TYPE.flightDetails]: { component: FlightDetails },
  [MODAL_TYPE.refreshPrice]: { component: GetFreshPricesModal },
  [MODAL_TYPE.staleBooking]: { isStandalone: true, component: UpdateBookingModal },
  [MODAL_TYPE.resumeBooking]: { isStandalone: true, component: ResumeBookingModal },
  [MODAL_TYPE.regionalSettings]: { isStandalone: true, component: RegionalSettings },
  [MODAL_TYPE.shareOptions]: { isStandalone: true, component: ShareItinerary },
  [MODAL_TYPE.loginOptions]: { component: LoginOptions },
  [MODAL_TYPE.signInOffer]: { component: SignInPopup },
  [MODAL_TYPE.createAccount]: { component: RegisterByEmail },
  [MODAL_TYPE.verifyEmail]: { component: VerifyNewEmail },
  [MODAL_TYPE.resetLinkSent]: { component: ResetLinkSent },
  [LOGIN_VENDOR.apple]: { component: null },
  [LOGIN_VENDOR.facebook]: { component: null },
  [LOGIN_VENDOR.google]: { component: null },
  [LOGIN_VENDOR.email]: { component: LoginByEmail },
};

const ModalContext = createContext({
  closeModal: (type, callback) => {},
  openModal: (type, modalProps, callback) => {},
  clearModals: (callback) => {},
});

export const ModalContextManager = (props) => {
  const location = useLocation();
  const [modalStack, setStackState] = useState([]);

  const isModalTypeInStack = (stack, type) => {
    return stack.findIndex((m) => m.type === type) !== -1;
  };

  const addModalToStack = useCallback((type, modalProps) => {
    let success = true;
    setStackState((stack) => {
      if (!isModalTypeInStack(stack, type)) {
        return [
          ...stack,
          {
            id: nanoid(),
            type,
            props: modalProps,
            isClosing: false,
            onClose: modalProps?.onClose ? modalProps.onClose : () => {},
          },
        ];
      }
      success = false;
      return stack;
    });
    return success;
  }, []);

  const removeModalFromStack = (type) => {
    let success = true;
    setStackState((stack) => {
      const modalTypeIndex = stack.findIndex((m) => m.type === type);
      if (modalTypeIndex !== -1) {
        const newStack = stack.slice();
        newStack.splice(modalTypeIndex, 1);
        return newStack;
      }
      success = false;
      return stack;
    });
    return success;
  };

  const onCloseModal = useCallback((type, callback) => {
    setStackState((stack) => {
      if (isModalTypeInStack(stack, type)) {
        const stackCopy = stack.slice();
        const modalTypeIndex = stackCopy.findIndex((m) => m.type === type);
        stackCopy[modalTypeIndex].isClosing = true;
        return stackCopy;
      }
      return stack;
    });
    setTimeout(() => {
      const success = removeModalFromStack(type);
      if (success && callback) callback();
    }, 200);
  }, []);

  const onOpenModal = useCallback(
    (type, modalProps, callback) => {
      const success = addModalToStack(type, modalProps);
      if (success && callback) callback();
    },
    [addModalToStack]
  );

  const closeAllModals = useCallback((callback) => {
    setStackState((stack) => {
      return stack.map((m) => {
        return { ...m, isClosing: true };
      });
    });
    setTimeout(() => {
      let success = true;
      setStackState((stack) => {
        if (stack.length === 0) {
          success = false;
        }
        return [];
      });
      if (success && callback) callback();
    }, 200);
  }, []);

  useEffect(() => {
    setStackState([]);
  }, [location]);

  return (
    <ModalContext.Provider
      value={{
        closeModal: onCloseModal,
        openModal: onOpenModal,
        clearModals: closeAllModals,
      }}>
      {modalStack.slice().map((modal, i) => {
        if (MODAL_TYPE_COMPONENT[modal.type].isStandalone && i > 0) {
          return null;
        } else {
          const ModalComponent = MODAL_TYPE_COMPONENT[modal.type].component;
          const modalProps = {
            ...modal.props,
            modalId: modal.id,
            order: i,
            onClose: (...args) => onCloseModal(modal.type, () => modal.onClose(...args)),
            state: { isClosing: modal.isClosing },
          };
          return <ModalComponent key={modal.id} {...modalProps} />;
        }
      })}
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContext;

export const useModalContext = () => useContext(ModalContext);
