import { Fragment } from "react";
import SaleBanners from "src/components/features/home/SaleBanners/SaleBanners";
import TopDestinations from "src/components/features/home/TopDestinations/TopDestinations";
import SearchForm from "src/components/features/search/SearchForm/SearchForm";
import { useDeviceContext } from "src/context/device-context";

function HomePage() {
  const { isMobile } = useDeviceContext();

  return (
    <Fragment>
      <main id="tickets-home-page" className={isMobile ? "mobile" : "desktop"}>
        <SearchForm />
        <div style={{ backgroundColor: "#fff", minHeight: "400px" }}>
          <SaleBanners />
          {isMobile && <TopDestinations />}

          {/* <section style={{ width: "100%", margin: isMobile ? "3rem 0 0" : "5rem 0" }}>
            <div style={{ padding: isMobile ? "0" : "0 15.25rem" }}>
              <h1 style={{ fontSize: "20px", marginBottom: "1rem" }}>
                Tailor your travel experience to your individual needs See more services
              </h1>
              <div
                style={{
                  height: "220px",
                  border: "1px solid var(--ui-2)",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  borderRadius: "10px",
                }}>
                {[1, 2, 3, 4].map((i) => (
                  <div
                    key={i}
                    style={{
                      width: "100%",
                      backgroundColor: "#f0f3f570",
                      borderRadius: "10px",
                      margin: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}>
                    {[1, 2, 3, 4].map((j) => (
                      <div
                        key={`${i}-${j}`}
                        style={{
                          height: "20px",
                          width: (100 * 1) / (j + 1 / 4) + "%",
                          backgroundColor: "#bac7d51f",
                          borderRadius: "10px",
                          margin: "10px",
                        }}></div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </section> */}
        </div>
        {/* <section style={{ width: "100%", margin: isMobile ? "0" : "5rem 0" }}>
          <div style={{ padding: isMobile ? "0" : "0 15.25rem" }}>
            <h1 style={{ fontSize: "20px", marginBottom: "1rem" }}>
              Popular flybee.lv cheap flights
            </h1>
            <p>
              Other Travellers are loving these destinations. Search flights, hotels and car
              hire and join them on adventure.
            </p>
            <div
              style={{
                display: "grid",
                gap: "25px",
                gridTemplateColumns: "1fr 1fr 1fr",
                margin: "2rem 0",
              }}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                <div
                  key={nanoid(4)}
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "#fff",
                    borderRadius: "7px",
                    overflow: "hidden",
                    display: "flex",
                  }}>
                  <div
                    style={{
                      backgroundColor: "#bac7d56b",
                      width: "30%",
                      height: "100%",
                    }}></div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      padding: "1.5rem",
                      rowGap: "1rem",
                    }}>
                    <div
                      style={{
                        height: "20px",
                        backgroundColor: "#bac7d51f",
                        borderRadius: "10px",
                      }}></div>
                    <div
                      style={{
                        height: "20px",
                        width: "50%",
                        backgroundColor: "#bac7d51f",
                        borderRadius: "10px",
                      }}></div>
                  </div>
                </div>
              ))}
            </div>

            <div style={{ marginBottom: "2rem" }}>useful tips and info</div>
          </div>
        </section> */}
      </main>
    </Fragment>
  );
}

export default HomePage;
