import { Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "src/components/shared/footer/Footer/Footer";
import TopNavigation from "src/components/shared/navigation/TopNavigation/TopNavigation";

function WithNavigationLayout() {
  const { pathname } = useLocation();
  // const hideFooter = pathname === "/search/results";
  const hideFooter = true;

  return (
    <Fragment>
      <TopNavigation />
      <Outlet />
      {!hideFooter && <Footer />}
    </Fragment>
  );
}

export default WithNavigationLayout;
