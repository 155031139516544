import { forwardRef, useRef, useState } from "react";
import { useDeviceContext } from "src/context/device-context";
import useClickAway from "src/hooks/useClickAway";
import useMousedownAway from "src/hooks/useMousedownAway";
import { customLog } from "src/utils/utils";
import classes from "./SearchFormInput.module.css";

export const DesktopSearchFormInput = forwardRef(function DesktopSearchFormInput(
  { id, value, iata, label, placeholder, onChangeInput, onClick, className },
  ref
) {
  const { isMobile } = useDeviceContext();
  const inputRef = useRef();
  const frameRef = useRef();

  const [hasFocus, setHasFocus] = useState(null);
  const [clickCount, setClickCount] = useState(0);

  const classname = !isMobile ? className : classes.active;

  const onSelectText = (clickEvent) => {
    customLog(clickCount);
    if (inputRef.current && clickCount === 0) {
      inputRef.current.select();
    } else if (inputRef.current) {
      if (!inputRef.current.contains(clickEvent.target)) {
        customLog(value);
        inputRef.current.setSelectionRange(value.length, value.length);
      }
      inputRef.current.focus();
      customLog("should focus input " + clickCount);
    }
    setClickCount((p) => p + 1);
  };

  const onChangeHandler = (e) => {
    onChangeInput(e.target.value);
  };

  const onClickInputHandler = (e) => {
    setHasFocus(true);
    onSelectText(e);
    onClick();
  };

  const resetClickCount = () => setClickCount(0);
  useMousedownAway(frameRef, resetClickCount);
  useClickAway(frameRef, resetClickCount);

  return (
    <div
      ref={(r) => {
        frameRef.current = r;
        ref[id === "src-location" ? "src" : "dest"] = r;
      }}
      id={"search-form-input-" + id}
      className={`${classes.frame} ${classname ?? ""}`}
      onClick={(e) => onClickInputHandler(e)}>
      <label htmlFor={id} className={hasFocus || value ? classes.smallText : classes.bigText}>
        <span onClick={(e) => e.stopPropagation()}>{label}</span>
        <input
          id={id}
          ref={inputRef}
          type="text"
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          onChange={onChangeHandler}
          onBlur={() => setHasFocus(false)}
          onClick={(e) => {
            e.stopPropagation();
            onClickInputHandler(e);
          }}
        />
      </label>
      {iata && <span className={classes.smallText}>{iata}</span>}
    </div>
  );
});
