import { useReducer } from "react";
import classes from "./ExpandingNavList.module.css";
import { NavLink } from "react-router-dom";
import { CollapseArrow, ExpandArrow } from "src/components/UI/Arrow/Arrow";
import { useLocaleContext } from "src/context/locale-context";

const listStateReducer = (prevState, action) => {
  if (action.type === "toggle") {
    let newState = [...prevState];
    for (let i = 0; i < newState.length; i++) {
      if (i === action.payload.itemNum) {
        newState[i].expanded = action.payload.value;
      } else {
        newState[i].expanded = false;
      }
    }
    return newState;
  }
};

function ExpandingNavList(props) {
  const { stringRes } = useLocaleContext();
  const [state, dispatch] = useReducer(
    listStateReducer,
    props.items.map((item) => {
      return { ...item, expanded: false };
    })
  );

  const onToggleHandler = (i) => {
    dispatch({
      type: "toggle",
      payload: { itemNum: i, value: !state[i].expanded },
    });
  };

  const renderListItem = (item, i) => {
    const outerItem = (
      <li
        key={item.name}
        className={`${classes.child} ${classes.border} ${
          state[i].expanded ? classes.none : ""
        }`}
        onClick={() => onToggleHandler(i)}>
        <span>
          {props.uppercase ? stringRes[item.name].toUpperCase() : stringRes[item.name]}
        </span>
        {state[i].expanded ? <CollapseArrow /> : <ExpandArrow />}
      </li>
    );

    const innerItems = state[i].expanded ? (
      <ul
        key={item.name + "_sublist"}
        className={`${classes.container} ${classes.border}`}>
        {item.items.map((innerItem, y) => (
          <li key={`${item.name + "_item"}${y}`} className={classes.link}>
            <NavLink to={innerItem.to}>{stringRes[innerItem.name]}</NavLink>
          </li>
        ))}
      </ul>
    ) : (
      []
    );

    return [outerItem, innerItems];
  };

  return (
    <ul className={`${classes.parent} ${props.className}`}>
      {state.map((item, i) => renderListItem(item, i))}
    </ul>
  );
}

export default ExpandingNavList;
